import { absLayout, button, debugDeriver, dock, grid, list, stack } from "../../../helium-star/src"
import { APP } from "../state/app.state"
import { renderUser } from "./contact";
import { renderContactsView } from "./contacts-view";
import { renderGathering } from "./gathering";
import { renderGatheringsView } from "./gatherings-view";
import { renderMainSettings } from "./main-settings";
import { renderProfileSettings } from "./profile-settings";
import { renderEditGathering } from "./create-gathering";
import { renderAppInvites } from "./app-invites";
import { renderNavList } from "./nav-list";
import { renderBackButton } from "./back-button";
import { renderNotificationList } from "./notification-list";
import { colorAsRgb } from "../styling/colorNameToHex";
import { renderAltLogins } from "./alt-logins";
import { setupPushNotifications } from "../../html/push-note-setup";

export function renderMainDash() {
  return renderMainView();
  // return dock("MainDash", {
  //   fill: renderMainContent(),
  //   bottom: button({
  //     onPush: () => APP().view = "nav-list",
  //     innards: "☰"
  //   }),
  //   // bottom: renderMainNav()
  // });

  // function renderMainNav() {
  //   return grid("MainNav", {
  //     rows: "auto",
  //     columns: "*,*,*"
  //   },[
  //     button("Gathers", {
  //       onPush: () => APP().view = "invites",
  //       ddxClass: () => APP().view !== "invites" && "opacity-50",
  //       innards: "Gatherings"
  //     }),
  //     button("Contacts", {
  //       col: 2,
  //       onPush: () => APP().view = "contacts",
  //       ddxClass: () => APP().view !== "contacts" && "opacity-50",
  //       innards: "Contacts"
  //     }),
  //     button("Settings", {
  //       col: 3,
  //       onPush: () => APP().view = "settings",
  //       ddxClass: () => APP().view !== "settings" && "opacity-50",
  //       innards: "Settings"
  //     }),
  //   ])
  // }
}


export function renderMainView() {
  return grid("MainView height-full overflow-hidden", {
    columns: "*,auto,*",
    rows: "auto,*,auto",
    innards: [
      () => {
        const {title} = APP().currentViewSetup;
        if (!title) { return; }
        return stack("Topbar m-0 p-10 text-center text-xl bg-none", {
          row: 1,
          colSpan: 3,
          innards: typeof title === "string" ? title : title(APP().view),
          ddxClass: () => {
            const { r, g, b } = colorAsRgb(APP().me.avatar.color1) || { r: 0, g: 0, b: 0};
            const lum = r*.21 + g*.72 + b*.07;
            console.log(lum);
            return lum < (255/2) ? "text-white" : "text-black";
          }
        });
      },
      renderMainContent(),

      () => APP().drawerOpen && stack("BlackOut bg-black opacity-50", {
        col: 1,
        colSpan: 3,
        row: 1,
        rowSpan: 3,
        style: "transition: all ease-out .1s",
        onPush: () => APP().drawerOpen = false
      }),

      stack("Drawer bg-white card m-0 rounded-none", {
        col: 2,
        colSpan: 2,
        row: 1,
        rowSpan: 3,
        ddxClass: () => APP().drawerOpen ? "left-0" : "left-full",
        style: "transition: all ease-out .1s"
      },[
        renderNavList(),
      ]),
      
      renderRootwardsButton(),
    ]
  })
}



export function renderMainContent() {
  return stack("MainDashContent bg-whiten-700", {
    row: 2,
    rowSpan: 2,
    colSpan: 3
  },() => {
    const view = APP().view;

    if (view.startsWith("contact/view/")) {
      return renderUser(view.split("/")[2])

    } else if (view.startsWith("gathering/edit/")) {
      return renderEditGathering(view.split("/")[2]);

    } else if (view.startsWith("gathering/view/")) {
      return renderGathering(view.split("/")[2]);

    } else if (view.startsWith("gatherings")) {
      return renderGatheringsView(view.split("/")[1]);

    } else if (view.startsWith("contacts/")) {
      return renderContactsView(view.split("/")[1]);

    } else {
      switch (view) {
        case "settings": return renderMainSettings();
        case "profile": return renderProfileSettings();
        case "app-invites": return renderAppInvites();
        case "notifications": return renderNotificationList();
        case "alt-logins": return renderAltLogins();
      }
    }
    
    APP().view = "gatherings";
  });
}



function renderRootwardsButton() {
  return stack({
    row: 3,
    col: 2,
    innards: () => {
      if (!APP().me) { return; }
      let { rootwardsStyle, onRootwards} = APP().currentViewSetup;
      if (rootwardsStyle === "none") { return; }
      if (APP().drawerOpen) {
        rootwardsStyle = "close";
        onRootwards = undefined;
      }
      onRootwards = onRootwards || rootwardsStyle;
      return button({
        onPush: () => {
          setupPushNotifications();
          if (onRootwards === "back") {
            APP().goToLastView();
          } else if (onRootwards === "drawer") {
            APP().drawerOpen = true;
          } else if (onRootwards === "close") {
            APP().drawerOpen = false;
          } else if (typeof onRootwards === "function") {
            onRootwards();
          } else {
            APP().view = onRootwards.view;
          }
        },
        innards: rootwardsStyle === "back" ? "←" :
          rootwardsStyle === "close" ? "X" :
          rootwardsStyle === "drawer" ? "☰" : "?",
      })
      // if (["gatherings", "contacts", "settings", "log-in" ].includes(APP().view) === false) {
      //   return button({
      //     onPush: () => APP().view = APP().lastView,
      //     innards: "←"
      //   });
      // }
      // return button({
      //   onPush: () => APP().drawerOpen = !APP().drawerOpen,
      //   innards: APP().drawerOpen ? "X" : "☰",
      // })
    }
  }) 
}