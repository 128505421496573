import { DdxSource, Sourcify } from "helium-sdx";
import { ComplexJoinManager, EditingState, jit } from "helium-source-repo";
import { UserManager, RoleManager, GatherManager, RoleRepo, UserEntity, InviteManager, PairingManager, NotificationManager } from "./ents"
import { InviteRepo } from "./ents/Invite.ents";
import { PushNotifications } from "./tools/PushNotifications";
import { TogatherApi } from "./tools/TogatherApi";

export type Role = { 
  owner: string, 
  target: string, 
  context?: string, 
  type: "add-contact" | "contact" | "invite" | "going-to" | "interested-in" | "archive-invite"
};


export function APP() {
  return TogetherClient.singleton;
}


export class TogetherClient {
  public static singleton: TogetherClient;
  constructor() {
    if (TogetherClient.singleton) {
      throw new Error(`Only one client may exist at a time`);
    }
    TogetherClient.singleton = this;

    typeof window !== "undefined" && ((window as any).APP = this);
  }

  public editingState = new EditingState();

  public get api() {
    return jit(this, "api", () => new TogatherApi());
  }

  public get ents() {
    return jit(this, "ents", () => ({
      user: new UserManager(),
      role: new RoleManager(),
      gather: new GatherManager(),
      invite: new InviteManager(),
      pairing: new PairingManager(),
      notify: new NotificationManager(),
    }))
  }

  public get repos() {
    return jit(this, "repos", () => ({
      user: this.ents.user.repo,
      role: this.ents.role.repo as RoleRepo,
      gather: this.ents.gather.repo,
      invite: this.ents.invite.repo as InviteRepo,
      pairing: this.ents.pairing.repo,
      notify: this.ents.notify.repo,
    }))
  }

  public get join() {
    return jit(this, "join", () => {
      const manager = new ComplexJoinManager({
        gqlApiCall: this.api.url("/graphql"),
        queryRoot: {
          "Roles": this.ents.role.repo,
          "Invites": this.ents.invite.repo,
          "Notifications": this.ents.notify.repo,
          "Pairings": this.ents.pairing.repo,
        }
      });
  
      const { user, role, gather, invite, pairing, notify } = this.ents;
      
      return {
        manager,
        get runQuery() { return manager.gql },
        get queryHandler() { return manager.gql.handler },
        user: user.joinOn.bind(user) as typeof user["joinOn"],
        role: role.joinOn.bind(role) as typeof role["joinOn"],
        gather: gather.joinOn.bind(gather) as typeof gather["joinOn"],
        invite: invite.joinOn.bind(invite) as typeof invite["joinOn"],
        pairing: pairing.joinOn.bind(pairing) as typeof pairing["joinOn"],
        notify: notify.joinOn.bind(notify) as typeof notify["joinOn"],
      }
    });
  }


  protected _me = new DdxSource(() => {
    const { userId } = this.api.loginState.current || {};
    if (!userId) { return null; }
    return this.ents.user.get(userId);
  });

  public get me() { return this._me.get(); }

  public pushNotifications = new PushNotifications();
}