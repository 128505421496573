import { RoleEntity, UserEntity } from "../../../client/src/ents";
import { button, label, labelH1, stack } from "../../../helium-star/src";
import { APP } from "../state/app.state";
import { CSS } from "../styling/common";
import { renderEmojiAvatar } from "./emoji-avatar";
import { renderLabeledInput } from "./labeled-input";
import { renderSaveEditsButton } from "./save-edits-button";


APP.registerViewSetup({
  rootwardsStyle: "back",
  // onRootwards: "back",
  matchCheck: (view) => view.startsWith("contact/view/"),
  title: null,
})


export function renderUser(id: string) {
  return stack("User", () => {
    const user = APP().ents.user.get(id);
    if (user._is.loading) {
      return "Loading...";
    } else {
      user.reload();
    }
    
    return [
      renderHeader(user),
      "",
      stack("Connection", () => {
        const conn = APP().me.getConnection(user);
        if (conn === "Loading") { return; }
        const editMe = conn && conn.role.getEditor();

        return [
          renderConnectionStatus(conn),
          editMe && renderSettings(editMe),
        ]
      }),
    ]
  })

  // ----------

  function renderHeader(user: UserEntity) {
    return [
      stack("p-10", [
        renderEmojiAvatar(() => ({
          size: "6xl",
          ...user.avatar
        })),
      ]),
      () => label("text-2xl text-center", user.displayName),
    ]
  }
 

      
  function renderConnectionStatus(conn: { role: RoleEntity }) {
    return stack("Status text-center", () => {
      if (!conn) {
        return button("Connect", {
          onPush: () => APP().ents.role.new({ownerId: APP().me.id, targetId: id, type: "contact", status: "pending"}).save(),
          innards: "Connect"
        })

      } else if (conn.role.status === "accepted") {
        return "Connected";
      }

      return conn.role.ownerId === APP().me.id ? "Connection Pending..." : button("AcceptContact", {
        onPush: () => conn.role.asContact.accept(),
        innards: "Accept Contact Request"
      }); 
    });
  }

    

  function renderSettings(editMe: RoleEntity) {
    console.log(editMe);
    return [
      stack("li-card", [
        renderLabeledInput({
          sizing: [2, 7],
          label: "Memory Message",
          input: {
            placeholder: "Always down for a game night!",
            innards: {
              get: () => editMe.asContact.messageFrom(APP().me),
              set: (val: string) => editMe.asContact.setMessage(val)
            }
          }
        })
      ]),

      renderSaveEditsButton({
        editor: editMe,
        text: "Save Changes",
      })
    ]
  }
}

