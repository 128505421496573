import { 
	LayoutBase, FlexboxLayout, AbsoluteLayout, StackLayout, 
	TextField, Button, 
	Image, DockLayout, SegmentedBar, ListView, ItemEventData, ObservableArray, GridLayout, ActionBar, ActionItem 
} from "@nativescript/core";
import { SegmentedBarItem, SelectedIndexChangedEventData } from "@nativescript/core/ui/segmented-bar";
import { BaseNode, Innards, IViewProps, PrimitiveInnards, SingleItemInnard } from "./el-tool/el-tool.io";
import {
	applyProps, standardizeInput, IPropsAndInnards,
	setInnards,
	convertInnardsToNodes,
} from "./helium";




function _layoutHelper<PROPS extends IViewProps = IViewProps>(
	layout: LayoutBase,
	classOrProps?: string | PROPS,
	propsOrInnards?: PROPS | Innards,
	innardsOrNot?: Innards,
): IPropsAndInnards<PROPS> {
	const input = standardizeInput<PROPS>(classOrProps, propsOrInnards, innardsOrNot);
	const { props, innards } = input;
	applyProps(layout as any, props);
	if (innards) { setInnards(layout, innards); }
	layout.ensureDomNode();

	return input;
}




/*********************
 * FLEX
 */
export interface IFlexProps extends IViewProps {
	flexDirection?: "row" | "column" | "row-reverse" | "column-reverse";
}
export function flex(
	classOrProps?: string | IFlexProps,
	propsOrInnards?: IFlexProps | Innards,
	innardsOrNot?: Innards,
) {
	const out = new FlexboxLayout();
	const { props } = _layoutHelper<IFlexProps>(out, classOrProps, propsOrInnards, innardsOrNot);
	if (props.flexDirection) { out.flexDirection = props.flexDirection; }
	return out;
}

export function center(
	classOrProps?: string | IFlexProps,
	propsOrInnards?: IFlexProps | Innards,
	innardsOrNot?: Innards,
) {
	const out = new FlexboxLayout();
	out.justifyContent = "center";
	const { props } = _layoutHelper<IFlexProps>(out, classOrProps, propsOrInnards, innardsOrNot);
	out.flexDirection = props.flexDirection || "column";
	return out;
}



/*********************
 * AbsLayout
 */
export function absLayout(
	classOrProps?: string | IViewProps,
	propsOrInnards?: IViewProps | Innards,
	innardsOrNot?: Innards,
) {
	const out = new AbsoluteLayout();
	const { props } = _layoutHelper<IViewProps>(out, classOrProps, propsOrInnards, innardsOrNot);
	return out;
}


/*********************
 * STack
 */
export function stack(
	classOrProps?: string | IViewProps,
	propsOrInnards?: IViewProps | Innards,
	innardsOrNot?: Innards,
) {
	const out = new StackLayout();
	const { props } = _layoutHelper<IViewProps>(out, classOrProps, propsOrInnards, innardsOrNot);
	return out;
}


/*********************
 * Dock
 */
export type DockInnards = {
	top?: BaseNode;
	left?: BaseNode;
	right?: BaseNode;
	bottom?: BaseNode;
	fill?: BaseNode;
}
export interface IDockProps extends IViewProps {
	stretchLastChild?: boolean;
}
export function dock(
	classOrProps?: string | IDockProps,
	propsOrInnards?: IDockProps | DockInnards,
	innardsOrNot?: DockInnards,
) {
	const out = new DockLayout();
	const innardsRazor = (checkMe: any): checkMe is DockInnards => {
		return ("top" in checkMe || "left" in checkMe || "right" in checkMe || "bottom" in checkMe || "fill" in checkMe);
	};
	const { props, innards } = standardizeInput(classOrProps, propsOrInnards, innardsOrNot, innardsRazor);
	props && applyProps(out as any, props);
	if (innards) {
		let children: BaseNode[] = [];
		for (let _key in innards) {
			const key = _key as keyof DockInnards;
			const child = innards[key];
			if (child) {
				key !== "fill" && (child.dock = key); 
				console.log(child.dock);
				children.push(child);
			}
		}
		children = children.sort((a, b) => a.dock ? -1 : 1);
		setInnards(out, children);
	}
	console.log("dock props", JSON.stringify(props));
	return out;
}



// /*********************
//  * Wrap
//  */
// export interface IWrapProps extends IViewProps {
// 	orientation?: "horizontal" | "vertical";
// }
// export function wrap(
// 	classOrProps?: string | IWrapProps,
// 	propsOrInnards?: IWrapProps | Innards,
// 	innardsOrNot?: Innards,
// ) {
// 	const out = new WrapLayout();
// 	const { props } = _layoutHelper<IWrapProps>(out, classOrProps, propsOrInnards, innardsOrNot);
// 	if (props.orientation) {
// 		out.orientation = props.orientation;
// 	}
// 	return out;
// }


/*********************
 * Grid
 */
export interface IGridLayout extends IViewProps {
	columns?: string;
	rows?: string;
}
export function grid(
	classOrProps?: string | IGridLayout,
	propsOrInnards?: IGridLayout | Innards,
	innardsOrNot?: Innards,
) {
	const out = new GridLayout();
	const { props } = _layoutHelper<IGridLayout>(out, classOrProps, propsOrInnards, innardsOrNot);
	if (props.columns) {
		(out as any).columns = props.columns;
	}
	if (props.rows) {
		(out as any).rows = props.rows;
	}
	return out;
}


/*********************
 * Textfield
 */
export type ITextFieldProps = IViewProps & Partial<Pick<
	TextField, 
	"text" | "keyboardType" | "returnKeyType" | "autocapitalizationType" | "secure"
>> & {
	textChange?: (text?: string) => void;
	placeholder?: string;
} 

export function textField(
	classOrProps?: string | ITextFieldProps,
	propsOrText?: ITextFieldProps | string,
	text?: string,
) {
	const out = new TextField();
	const { props, innards } = standardizeInput<ITextFieldProps>(classOrProps, propsOrText, text);
	if (props) {
		if (props.textChange) { out.addEventListener("textChange", () => props.textChange!(out.text)); }
		// if (props.observableStore) {
		// 	if (props.text) { throw new Error("Can not define both text and observable store for textfield"); }
		// 	props.text = props.observableStore.get();
		// 	out.on("textChange", () => props.observableStore.set(out.text));
		// }
		// if (props.secure) { out.secure = true; }
		if (props.placeholder) { out.hint = out.hint || props.placeholder; }
		// if (props.disableFlagEmpty !== true) { addTextFieldFlagging("empty", out); }
		if (props.keyboardType) {
			out.keyboardType = props.keyboardType;
			if (props.keyboardType === "email" && !props.autocapitalizationType) {
				props.autocapitalizationType = "none";
			}
		}
		// if (props.autocapitalizationType) {
		// 	out.autocapitalizationType = props.autocapitalizationType;
		// }
		applyProps(out, props);
	}
	out.text = (props?.text || "") + (innards || "");
	return out;
}




export type ActionBarProps = {
	title?: string
}

/*********************
 * Action Bar
 */
 export function actionBar(
	classOrProps?: string | ActionBarProps,
	propsOrNot?: ActionBarProps
) {
	const [ className, props ] = typeof classOrProps === "string" ? [classOrProps, propsOrNot] : [undefined, propsOrNot];
	const out = new ActionBar();
	out.className = className || "";
	out.title = props?.title || "";
	const addMe = new ActionItem();
	addMe.text = "THIS??"
	out.actionItems.addItem(addMe);
	return out;
}


/*********************
 * Button
 */
export interface IButtonProps extends IViewProps {
	onPush: () => void;
	text?: string;
}
export function button(
	classOrProps?: string | IButtonProps,
	propsOrInnards?: IButtonProps | string,
	innardsOrNot?: string,
) {
	// throw new Error("Button appears to be a")
	const out = new Button();
	const { props, innards  } = standardizeInput<IButtonProps>(classOrProps, propsOrInnards, innardsOrNot);
	props.text = (props.text || "") + (innards as string || "");
	if (props) {
		applyProps(out, props);
		if (props.onPush) { out.addEventListener(Button.tapEvent, props.onPush); }
	}
	return out;
}


/*********************
 * Image
 */
export interface IImageProps extends IViewProps {
	src?: string;
	stretch?: "none" | "aspectFill" | "aspectFit" | "fill"
}
export function img(
	classOrProps?: string | IImageProps,
	propsOrInnards?: IImageProps | string,
	innardsOrNot?: string,
) {
	const out = new Image();
	const { props, innards  } = standardizeInput(classOrProps, propsOrInnards, innardsOrNot);
	props && applyProps(out, props);
	return out;
}



// /*********************
//  * ScrollView
//  */
// export interface IScrollViewProps extends IViewProps {
// 	orientation?: "horizontal" | "vertical"
// }
// export function scrollView(
// 	classOrProps?: string | IScrollViewProps,
// 	propsOrChild?: IScrollViewProps | Child,
// 	childOrNot?: Child,
// ) {
// 	const out = new ScrollView();
// 	const { props, innards  } = standardizeInput(classOrProps, propsOrChild, childOrNot);
// 	const child = innards as Child;
// 	applyProps(out, props);
// 	out.content = _convertChildToView(child);
// 	return out;
// }





/*********************
 * SegmentedBar
 */

export type SegmentedBarProps = IViewProps & {
  selectedIndex?: number;
  selectedIndexChange: (ev: SelectedIndexChangedEventData) => any;
}

export function segmentedBar(
	classOrProps?: string | SegmentedBarProps,
	propsOrInnards?: SegmentedBarProps | PrimitiveInnards,
	maybeInnards?: PrimitiveInnards,
) {
	const out = new SegmentedBar();
	const { props, innards  } = standardizeInput<SegmentedBarProps>(classOrProps, propsOrInnards, maybeInnards);
	props && applyProps(out, props);
	const children = Array.isArray(innards) ? innards : [innards];
	out.items = children.map((child) => {
		const addMe = new SegmentedBarItem();
		addMe.title = String(child);
		return addMe;
	});
	let lastIndex = props.selectedIndex || 0;
	out.on("selectedIndexChange", (_ev) => {
		const ev = _ev as SelectedIndexChangedEventData;
		ev.oldIndex = lastIndex;
		lastIndex = ev.newIndex = (<SegmentedBar>ev.object).selectedIndex;
		props.selectedIndexChange(ev);
	});

	return out;
}




/*********************
 * ListView
 */

export function list(
	classOrProps?: string | IViewProps,
	propsOrInnards?: IViewProps | SingleItemInnard[],
	maybeInnards?: SingleItemInnard[],
) {
	// const obsArr = new ObservableArray();
	const out = new ListView();
	const { props, innards } = standardizeInput(classOrProps, propsOrInnards, maybeInnards);
	props && applyProps(out, props);
	const items = convertInnardsToNodes(innards);
	out.items = items;
	out.on(ListView.itemLoadingEvent, ((args: ItemEventData) => {
		!args.view && (args.view = items[args.index]);
    // if (!args.view) {
    //     // Create label if it is not already created.
    //     args.view = new Label();
    //     args.view.className = "list-group-item";
    // }
    // (<any>args.view).text = listViewArray.getItem(args.index).title;

	}) as any);
	return out;
}