

export type ClientEnvData = {
  localApiPath: string;
}

export class ClientENV {
  public static singleton: ClientEnvData;

  public static setup(args: ClientEnvData) {
    if (ClientENV.singleton) {
      throw new Error(`ClientENV already setup()`);
    }
    this.singleton = args;
  }

  public static get() {
    if (!ClientENV.singleton) {
      throw new Error(`ClientENV.setup() must be called first`);
    }
    return ClientENV.singleton;
  }
}