import { DeriverScope } from "helium-sdx";

import type { ViewBase } from "@nativescript/core/ui/core/view-base";


/**************************
*. Innards
***************************/

export type HeliumElement = HTMLElement | ViewBase;
export type SinglePrimitiveInnard = string | number | null | false | void | undefined;
export type PrimitiveInnards = SinglePrimitiveInnard | SinglePrimitiveInnard[]
export type SingleItemInnard = SinglePrimitiveInnard | HeliumElement;
export type SingleInnard = PrimitiveInnards | HeliumElement;

export type RenderFnArgs = [HeliumElement | null, { scope: DeriverScope; store: any }]
export type StaticInnard = SingleInnard | (Array<StaticInnard> | ((...args: RenderFnArgs) => StaticInnard));


export type ManyStaticInnards = StaticInnard | Array<StaticInnard>;
export type Innards = ManyStaticInnards | ((...args: RenderFnArgs) => ManyStaticInnards);
// export type NonPrimativeInnards = BaseNode | Array<SingleInnard> | ((...args: RenderFnArgs) => ManyStaticInnards);

export type InnardsFn = ((...args: RenderFnArgs) => Innards)


type NonStringStaticInnard = Exclude<StaticInnard, string>;
export type NameOrPropsOrInnards<
	PROPTYPE extends IElProps, 
	INNARDS = NonStringStaticInnard | Array<SingleInnard>
> = string | PROPTYPE | INNARDS;
export type PropsOrInnards<PROPTYPE extends IElProps, INNARDS = Innards> = PROPTYPE | INNARDS;

export type EventHandler<T, A> = () => any;



/**************************
*. IElProps
***************************/
export type RefDdxFn<NODE extends HeliumElement, OUT = any> = (ref: NODE, store?: { [key: string]: any}) => OUT;

export type DdxClassValue = string | boolean | undefined | null;
export type DdxClassFn<NODE extends HeliumElement> = RefDdxFn<NODE, DdxClassValue | DdxClassValue[]>;

export interface IElProps<NODE extends HeliumElement = HeliumElement, INN = Innards> {
	id?: string;
	class?: string | null | undefined | Array<string | null | undefined>;
	// title?: string;
	// attr?: {[key: string]: string};
	style?: Partial<CSSStyleDeclaration> | string;
	// on?: {[key: string]: EventHandler<NODE, Event> };
	onPush?: EventHandler<NODE, any>;
	onTargetedPush?: EventHandler<NODE, any>;
	// onTouch?: EventHandler<NODE, ToucherEvent>;
	// onToucherMove?: EventHandler<NODE, ToucherMoveEvent>;
	// onToucherEnterExit?: EventHandler<NODE, ToucherEnterExitEvent>;
	// // onClick?: EventHandler<NODE, MouseEvent>;
	// // onMouseDown?: EventHandler<NODE, MouseEvent>;
	// onKeyDown?: EventHandler<NODE, KeyboardEvent>;
	// onHoverChange?: (isHovered: boolean, event?: MouseEvent) => EventResponse;
	// onFreeze?: EventHandler<NODE, Event>;
	// onUnfreeze?: EventHandler<NODE, Event>;
	this?: {
		[key: string]: any;
	} & Partial<NODE>;
	innards?: INN;
	ref?: (ref: NODE) => any;
	ddxClass?: DdxClassFn<NODE> | Array<DdxClassFn<NODE>>;
	ddx?: RefDdxFn<NODE> | Array<RefDdxFn<NODE>>;

	row?: number;
	rowSpan?: number;
	col?: number;
	colSpan?: number;
	// augments?: Array<ElementAugment> | ElementAugment;
}




/*****************************
* REGULAR CREATION SHORTHANDS
******************************/

/** Typical first factory arg.  Either className string, or factory props */
// export type StandardArg1<PROP> = string | PROP;

/** Typical second factory arg.  Either factory props, or innards to append */
// export type Props<PROP> = PROP | Innards;

/** Typical factory arguments */
export type StandardArgs<PROP = IElProps, INNARD = Innards> = []
	| [NameOrPropsOrInnards<PROP, INNARD>]
	| [NameOrPropsOrInnards<PROP, INNARD>, PropsOrInnards<PROP, INNARD>]
	| [NameOrPropsOrInnards<PROP, INNARD>, PropsOrInnards<PROP>, INNARD];

/** Typical factory arguments */
// export type NoClassArgs<PROP = IElProps, INNARD = Innards> = []
// 	| [PropsOrInnards<PROP>]
// 	| [PropsOrInnards<PROP>, INNARD];

export type CoP<PROP = IElProps> = NameOrPropsOrInnards<PROP>;
export type PoI<PROP = IElProps> = PropsOrInnards<PROP>;
export type Inn = Innards;


export type ElFunction<
	OUT extends HeliumElement = HeliumElement,
	INN = Innards,
	PROPS extends IElProps<OUT, INN> = IElProps<OUT, INN>,
> = {
	(): OUT;
	(className: string): OUT;
	(props: PROPS): OUT;
	(className: string, props: PROPS): OUT;

	(innards: Exclude<INN, string | HeliumElement>): OUT;
	(className: string, innards: Exclude<INN, HeliumElement>): OUT;
	(props: PROPS, innards: Exclude<INN, HeliumElement>): OUT;
	(className: string, props: PROPS, innards: INN): OUT;

	(
		nameOrPropsOrInnards?: string | PROPS | Exclude<INN, string | HeliumElement>,
		propsOrInnards?: PROPS | Exclude<INN, HeliumElement>,
		innards?: INN
	): OUT;
};

// export type ElFunctionTargetInnards<OUT extends HeliumElement, INN = Innards, PROPS extends IElProps<OUT> = IElProps<OUT>> =
// 	ElFunction<OUT, PROPS, INN>








// /**************************
// *. TYPES CHECKS
// ***************************/

/** Check if thing is DOM appendable node
 * @returnVar checkMeIsNode */
export function isNode(checkMe: any): checkMe is HeliumElement {
	return (typeof Node !== "undefined" && checkMe instanceof Node) || "_removeView" in checkMe;
}


// export function isHTMLElement(checkMe: any): checkMe is HTMLElement {
// 	return "nodeName" in checkMe;
// }

// export function isTextNode(checkMe: ChildNode): checkMe is Text {
// 	return isNode(checkMe) && checkMe.nodeType === checkMe.TEXT_NODE;
// }

// /** Check if thing reprensents raw html.  WARNING: Always double check raw html for injection.
//  * @returnVar checkMeIsRawHTML */
// export function isRawHTML(checkMe: any): checkMe is RawHTML {
// 	return (checkMe && typeof checkMe === "object") && checkMe.hasOwnProperty("hackableHTML");
// }

/** Check if thing represents rendering props for el-tool
 * @returnVar checkMeIsProps */
export function isProps(checkMe: any): checkMe is IElProps {
	return (checkMe && typeof checkMe === "object")
		&& !Array.isArray(checkMe)
		&& !isNode(checkMe)
}
