import { HeliumElement, IElProps, Innards, isProps } from "./el-tool.io";


/** Takes standard three arguments and returns a object with unambiguos props and innards.
 * Helper function for el() and factories such as div, span, etc.
 * @importance 16
 * @returnVar {props, innards}*/
 export function standardizeInput<
 INNARDS = Innards,
 PROPTYPE extends IElProps<HeliumElement, INNARDS> = IElProps<HeliumElement, INNARDS>,
>(
 nameOrPropsOrInnards?: string | PROPTYPE | INNARDS,
 propsOrInnards?:  PROPTYPE | INNARDS,
 maybeInnards?: INNARDS,
 isInnardsRazor?: (checkMe: PROPTYPE | INNARDS) => checkMe is INNARDS,
): {
 props: PROPTYPE,
 innards: INNARDS,
} {
 let innards: INNARDS = maybeInnards!;
 let props: PROPTYPE;
 let name: string;

 if (nameOrPropsOrInnards) {
   if (typeof nameOrPropsOrInnards === "string") {
     name = nameOrPropsOrInnards;
   } else {
     if (maybeInnards !== undefined) {
       throw new Error("Can not define first and third argument if first is not a string");
     }
     maybeInnards = propsOrInnards as any;
     propsOrInnards = nameOrPropsOrInnards;
   }
 }

 if (propsOrInnards || propsOrInnards as any === 0) {
   const isInnards = isInnardsRazor ? isInnardsRazor(propsOrInnards!) : !isProps(propsOrInnards);
   if (isInnards) {
     if (maybeInnards !== undefined) {
       throw new Error("Can not define third argument if second is innards" + maybeInnards);
     }
     innards = propsOrInnards as any;
   } else {
     props = propsOrInnards as any;
     innards = maybeInnards!;
   }
 }

 if (props! && (props.innards || (props.innards as any) === 0)) {
   if (innards && props.innards) {
     console.error(props, innards);
     throw new Error(`Can not specify both props.innards and innards argument`);
   }
   innards = props.innards as unknown as INNARDS;
   delete props.innards;
 }

 props = props! || {} as any;
 if (name!) {
   props.class = props.class || "";
   if (typeof props.class === "string") {
     props.class = name + (props.class ? " " : "") + props.class;
   } else {
     props.class.unshift(name);
   }
 }

 return { props, innards };
}
