// import "./contact-list.scss";

import { RoleEntity, UserEntity } from "../../../client/src/ents";
import { grid, label, labelH2, labelH5, list, segmentedBar, stack, scroll, button } from "../../../helium-star/src";
import { APP } from "../state/app.state";
import { renderEmojiAvatar } from "./emoji-avatar";


APP.registerViewSetup({
  rootwardsStyle: "drawer",
  matchCheck: (view) => view.startsWith("contacts/"),
  title: (view) => {
    switch (view.split("/")[1]) {
      case "known": return "Your Contacts";
      case "requests": return "Contact Requests";
      case "suggestions": return "Contact Suggestions";
    }
  }
})


export function renderContactsView(subView: string) {
  return scroll("ContactList messageOrElements", () => {
    switch(subView) {
      case "known": return () => {
        const { contacts } = APP().me;
        if (contacts === "Loading") { return; }
        const items = contacts.map(renderContactListItem);
        if (!items.length) {
          return "No contacts yet";
        }
        return list("Contacts", items);
      }
      case "requests": return () => {
        const { contactRequests } = APP().me;
        if (contactRequests === "Loading") { return; }
        const items = contactRequests.map(renderContactListItem);
        if (!items.length) {
          return "No pending contact requests";
        }
        return [
          button({
            innards: "refresh",
            onPush: () => APP().me.contactItemsHandler.refresh()
          }),
          list("Requests", items)
        ];
      }
      case "suggestions": return () => {
        const { contactSuggestions } = APP().me;
        if (contactSuggestions === "Loading") { return; }
        const items = contactSuggestions.map((user) => renderContactListItem({user, role: null}));
        if (!items.length) {
          return "No current suggestions";
        }
        return [
          button({
            innards: "refresh",
            onPush: () => APP().me.contactItemsHandler.refresh()
          }),,
          list("Suggestions", items)
        ];
      }
      default: throw new Error(`unknown sub-view "${subView}"`)
    }
  });
  // return grid("InvitesView", {
  //   rows: "auto, *",
  //   columns: "*",
  // },[
  //   // labelH5(["Contacts"]),
  //   // labelH2(["Contacts"]),

  //   segmentedBar("ContactViewSelector", {
  //     selectedIndex: APP()._store.contactsView,
  //     selectedIndexChange: (ev) => {
  //       APP()._store.contactsView = ev.newIndex
  //     },
  //     innards: [
  //       "Known", "Requests", "Suggested"
  //     ]
  //   }),

    
  // ])
}


export function renderContactListItem({role, user}: {
  role: RoleEntity | null,
  user: UserEntity,
}) {
  return grid("ContactListItem", {
    rows: "auto",
    columns: "*, 5*",
    onPush: () => APP().view = `contact/view/${user.id}`,
    innards: [
      renderEmojiAvatar(() => user.avatar.toObject() || {}),
      stack("px-10", [
        user.displayName,
        role && (() => {
          const msg = role.asContact.messageFrom(user);
          return msg && label("MemberMe italic text-sm text-gray-600", msg);
        })
      ])
    ]
  })
}