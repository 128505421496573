import { UserEntity } from "../../../client/src/ents";
import { button, grid, stack, PromiseSource, wrap, label } from "../../../helium-star/src"
import { generatePassphrase } from "../../../password-tool/src";
import { APP } from "../state/app.state";
import { Style } from "../styling/common";
import { renderEmojiAvatar } from "./emoji-avatar";
import { renderLabeledInput } from "./labeled-input";
import { renderSaveEditsButton } from "./save-edits-button";


export function renderJoinFromInvite(args: {
  joinParam: string
}) {
  return stack("JoinFromInvite", () => {

    // const [roleId, key] = args.joinParam.split("::");
    // if (!roleId || !key) {
    //   return stack("card colors-error", `Invalid formatting on join key "${args.joinParam}"`);
    // }

    const tokenResp = new PromiseSource("token", () => APP().api.validateJoinToken(args.joinParam));
    if (!tokenResp.get()) { 
      return stack("card colors-error", [
        label(Style.cardHeader, "Bad Token Error"),
        `The token "${args.joinParam}" is either malformed, or has already been consumed.`
      ]); 
    }

    const { user } = tokenResp.get() as { user: UserEntity }

    console.log(user)


    const { first, last, alias, password } = renderInputs();
    
    return [
      stack("card text-center", [
        label(Style.cardHeader, "Ahoy and Welcome!"),
        wrap({
          innards: [
            "You've been invited by ",
            renderEmojiAvatar(() => ({
              size: "sm",
              ...user.avatar.toObject()
            })), 
            user.displayName,
          ]
        }),
        "",
        "To create your account, we'll need a name a password:",
        stack("text-left", [
          first,
          last,
          alias,
          "",
          grid({
            rows: "auto",
            columns: "8*,*"
          }, [
            password,
            button("p-0 m-0 text-2xl",{
              innards: "🎲",
              onPush: async () => {
                const pw = await APP().api.getRandomPassword();
                password.value.set(pw);
              }
            })
          ])
        ])
      ]),
      renderSaveEditsButton({
        text: "Create Account",
        editor: {
          get hasEdits() { return !!(
            first.value.get() 
            && last.value.get()
            && password.value.get()
            && password.value.get().length >= 4
          )},
          save: async () => {
            const resp = await APP().api.createUser({
              joinToken: args.joinParam,
              user: {
                name: {
                  first: first.value.get(),
                  last: last.value.get(),
                  alias: alias.value.get(),
                },
                cleartextPassword: password.value.get(),
              }
            })
            return true;
          },
        },
        then: () => {
          APP().overlay = renderSuccessEnding(password.value.get())
        }
      })
    ]
  
    function renderInputs() {
      const first = renderLabeledInput({
        sizing: [2, 7],
        label: "First",
      });

      const last = renderLabeledInput({
        sizing: [2, 7],
        label: "Last",
        input: {}
      });

      const alias = renderLabeledInput({
        sizing: [2, 7],
        label: "Alias",
        input: { placeholder: "(optional)"}
      });

      const password = renderLabeledInput({
        sizing: [2, 6],
        label: "PW",
      });

      return {
        first,
        last,
        alias,
        password
      }
    }

    function renderSuccessEnding(pw: string) {
      return stack("card text-center", [
        label(Style.cardHeader, "Congrats!  You've created an account."),
        "There is currently no password recovery system, so make sure you remember yours:",
        "",
        pw,
        "",
        button({
          innards: "Copy Password"
        })
      ])
    }
  });
}