import { button, grid, label, stack, textField } from "../../../helium-star/src";
import { renderEmojiAvatar } from "./emoji-avatar";
import { renderLabeledInput } from "./labeled-input";
import { APP } from "../state/app.state";
import { renderSaveEditsButton } from "./save-edits-button";
import { CSS, Style } from "../styling/common";

  
APP.registerViewSetup({
  rootwardsStyle: "drawer",
  title: "Your Profile",
  matchCheck: "profile"
})

const EDIT_ID = "profile-settings";

export function renderProfileSettings() {
  const editMe = APP().me.getEditor(EDIT_ID);        
  console.log(editMe);
  // DerivationManager.debugDeriver();
  return stack("ProfileSettings", [
    renderNameEditor(),
    renderAvatarEditor(),
    renderSaveEditsButton({
      editor: editMe,
      text: "Update Profile",
    })
  ]);

  // -----------

  function renderNameEditor() {
    const first = renderLabeledInput({
      sizing: [2, 7],
      label: "First",
      input: { 
        // placeholder: "John",
        innards: editMe.name.getSource("first")  
      }
    });
    const middle = renderLabeledInput({
      sizing: [2, 7],
      label: "Middle",
      input: { 
        // placeholder: "Jacob",
        innards: editMe.name.getSource("middle")  
      }
    });
    const last = renderLabeledInput({
      sizing: [2, 7],
      label: "Last",
      input: { 
        // placeholder: "Jingleheimer",
        innards: editMe.name.getSource("last")  
      }
    });
    const alias = renderLabeledInput({
      sizing: [2, 7],
      label: "Alias",
      input: { 
        // placeholder: "Schmidt",
        innards: editMe.name.getSource("alias")  
      }
    });

    return stack("li-card", [
      label(Style.cardHeader, "Name"),
      first,
      middle,
      last,
      alias
    ])
  }

  function renderAvatarEditor() {
    const emoji = renderLabeledInput({
      sizing: [1, 2],
      label: "Emoji",
      input: { 
        placeholder: "⛵️",
        innards: editMe.avatar.getSource("emoji")  
      }
    });
    const color1 = renderLabeledInput({
      sizing: [1, 2],
      label: "Color #1",
      input: { 
        placeholder: "turquoise",
        innards: editMe.avatar.getSource("color1")  
      }
    });
    const color2 = renderLabeledInput({
      sizing: [1, 2],
      label: "Color #2",
      input: { 
        placeholder: "#95B",
        innards: editMe.avatar.getSource("color2")  
      }
    });
    return stack("li-card", [
      label(Style.cardHeader, "Avatar"),
      grid({
        columns: "2*, *",
        rows: "auto",
        innards: [
          stack({col: 1}, [emoji, color1, color2]),
          renderEmojiAvatar(() => ({
            size: "7xl",
            emoji: emoji.value.get(),
            color1: color1.value.get(),
            color2: color2.value.get()
          }))
        ]
      })
    ])
  }
}