import { Entity, EntityManager, SourceRepo } from "helium-source-repo";
import { ExampleRole, RoleArgs, RoleModel} from "../../../middleware/models/role.model";
import { RoleAPI } from "../../../middleware/endpoints/role.apiDatas";
import { commonPathSetups } from "./ents-util";
import { UserEntity } from "./User.ents";
import { DerivationManager, Sourcified } from "helium-sdx";
import { APP } from "../client";


export class RoleManager<
  ENT extends RoleEntity = RoleEntity
> extends EntityManager<RoleEntity> {
  initiateDefaultArgs(): void {
    this.defaultArgs = {
      repo: new RoleRepo(),
      createEntity: (args) => new RoleEntity(args),
    };
  }
}



export class RoleEntity 
extends Entity<RoleModel, "id", RoleArgs> 
implements Record<keyof RoleModel, any> {
  public get id() { return this.getId(); }
  public get createdAt() { return this.getProp("createdAt"); }
  public get ownerId() { return this.getProp("ownerId"); }
  public get targetId() { return this.getProp("targetId"); }
  public get type() { return this.getProp("type"); }
  public get endedAt() { return this.getProp("endedAt"); }
  public get contextId() { return this.getProp("contextId"); } 
  public get status() { return this.getProp("status"); } 
  public get details() { return this.getProp("details", {} as any) as Sourcified<RoleModel["details"]>; } 

  public get owner() {
    if (this.ownerId) { return APP().ents.user.get(this.ownerId); }
    return null;
  }

  public get target() {
    if (this.targetId) { return APP().ents.user.get(this.targetId); }
    return null;
  }
  
  public get asContact() {
    if (this.type !== "contact") {
      console.error(this);
      throw new Error(`RoleEntity is not of type "add-contact"`);
    }
    return {
      accept: async () => {
        if (this.status === "accepted") {
          throw new Error(`Contact already accepted`);
        }
        return this.getEditor().stageEdits({status: "accepted"}).save();
      },
      messageFrom: (user: UserEntity) => {
        const msg = this.details?.msg;
        const out = msg && msg.get(user.id === this.ownerId ? "owner" : "target")
        
        return out || "";
      },
      setMessage: (msg) => {
        this.demandEditorFor();
        const which = APP().me!.id === this.ownerId ? "owner" : "target";
        this.stageEdits({ details: { msg: { [which]: msg }}} as any, "merge-objects");
      }
    }
  }

  public updateHasEdits() {
    // try {
    //   DerivationManager.debugDeriver();
    // } catch(err) {}
    super.updateHasEdits();
         
  } 
}



export class RoleRepo
extends SourceRepo<RoleModel, "id", RoleArgs> {
  constructor() {
    super({
      idPropName: "id",
      repoId: "role",
      gqlPropTypes: {
        type: "enum"
      },
      ...commonPathSetups("role"),
      allFieldsNames: Object.keys(ExampleRole) as any
    }, {
      getJoinFilter: (key, item) => {
        switch(key) {
          case "Target": return { repo: APP().ents.user.repo, where: { id: item.targetId }}
          case "Owner": return { repo: APP().ents.user.repo, where: { id: item.ownerId }}
          case "Context": return { repo: APP().ents.gather.repo, where: { id: item.contextId }}
        }
        throw new Error("unknown name");
      },
      whereRules: {
        endedAt: null
      }
    })
  }

  public addContact = {
    accept: async (id: string) => {
      const resp = await APP().api.post(RoleAPI.addContact.accept.path, { id }) as typeof RoleAPI.addContact.accept.responseEg;
      this.dataSource.setItems(resp);
    },

    request: async (args: typeof RoleAPI.addContact.request.payloadEg) => {
      const resp = await APP().api.post(RoleAPI.addContact.request.path, args) as typeof RoleAPI.addContact.request.responseEg;
      this.dataSource.setItems(resp);
    }
  }

  // public async addContact.accept(id: string) {
  //   const resp = await APP().api.post(RoleAPI.addContact.accept.path, { id }) as typeof RoleAPI.addContact.accept.responseEg;
  //   this.dataSource.setItems(resp);
  // }

  // public async addContact.request(args: typeof RoleAPI.addContact.request.payloadEg) {
  //   const resp = await APP().api.post(RoleAPI.addContact.request.path, args) as typeof RoleAPI.addContact.request.responseEg;
  //   this.dataSource.setItems(resp);
  // }
}