import { ClientENV } from "../ENV";


export function commonPathSetups(name: string) {
  const prefix = `${ClientENV.get().localApiPath}/${name}`;
  return {
    getIdList: `${prefix}/find-ids`,
    getItems: `${prefix}/get-items`,
    createItems: `${prefix}/create`,
    deleteIds: `${prefix}/delete`,
    syncItemsToServer: `${prefix}/sync`,
  }
}