import { SelectNullable, SelectRequired } from "../misc/utility-types";

export const InviteIdPrefix = "nvit";


export const InviteResponseList = [ "going", "interested" ] as const;
export type InviteResponse = typeof InviteResponseList[number]; 

export const ExampleInvite = {
  id: InviteIdPrefix + ":1",
  createdAt: new Date(),
  // updatedAt: new Date(),
  // lastSeenAt: null as Date | null,
  archivedAt: null as Date | null,
  // fromId: "usr:112-123", 
  toId: "usr:2", 
  gatherId: "gthr:1", 
  response: "going" as InviteResponse,
  starred: true,
}


export type InviteModel = SelectNullable<typeof ExampleInvite, "response" | "archivedAt">;
export type InviteArgs = SelectRequired<InviteModel, "toId" | "gatherId">;