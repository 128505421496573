import { GatherEntity } from "../../../client/src/ents";
import { label, noDeps, stack, switchToggle } from "../../../helium-star/src";
import { APP } from "../state/app.state";
import { Style } from "../styling/common";
import { renderLabeledInput, renderLabeledItem } from "./labeled-input";
import { renderSaveEditsButton } from "./save-edits-button";


APP.registerViewSetup({
  matchCheck: (view) => view.startsWith("gathering/edit/"),
  rootwardsStyle: "close",
  onRootwards: "back",
  title: null,
})


export function renderEditGathering(idOrNew: string) {
  const isNewGather = idOrNew.toLowerCase() === "new";
  let editMe: GatherEntity;
  if (isNewGather) {
    editMe = APP().ents.gather.new({
      createdBy: APP().me.id,
      extendable: true,
    });

  } else {
    editMe = APP().ents.gather.get(idOrNew).getEditor();
    noDeps(() => {
      void editMe.name;
      void editMe.info;
      void editMe.extendable;
    })
  }
  return stack("CreateGathering", [
    "",
    "",
    "",
    renderFields(),
    renderSaveEditsButton({
      editor: editMe,
      text: editMe._is.template ? "Create" : "Save",
      then: () => {
        APP().replaceView(`gathering/view/${editMe.id}`);
      }
    })
  ]);

  function renderFields() {
    const name = renderLabeledInput({
      sizing: [2, 7],
      label: "Name",
      input: { 
        innards: editMe._stateData.getSource("name")  
      }
    });
    const info = renderLabeledInput({
      sizing: [2, 7],
      label: "Info *",
      input: { 
        innards: editMe._stateData.getSource("info")  
      }
    });
    const extendable = renderLabeledItem({
      sizing: [2, 7],
      label: "Extendable",
      item: switchToggle({
        col: 2,
        checked: editMe._stateData.getSource("extendable"),
      })
    })

    console.log(editMe);
    return stack("li-card", [
      label(Style.cardHeader, `${isNewGather ? "New" : "Edit"} Gathering`),
      name,
      info,
      extendable
    ])
  }

}
