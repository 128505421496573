import { VAP_ID_PUBLIC_KEY } from "../../middleware/misc/vapid";
import { APP } from "../shared/state/app.state";



export async function allowNotifications() {
  if (Notification.permission === "granted") {
    // console.log(new Notification("Hi there!"));
    return true;
  }
  return Notification.requestPermission().then((result) => result === "granted");
  // Notification.requestPermission().then((result) => {
  //   if (result === 'granted') {
  //     console.log(new Notification("Notify!"));
  //   }
  // });
}

let alreadySetup = false;
export async function setupPushNotifications() {
  if (alreadySetup) { return; }
  if ((await allowNotifications()) !== true) {
    return;
  }
  navigator.serviceWorker.register('/push-note-worker.js');

  const registration = await navigator.serviceWorker.ready;

  // Use the PushManager to get the user's subscription to the push service.
  let subscription = await registration.pushManager.getSubscription();
  if (!subscription) {
    const vapidPublicKey = VAP_ID_PUBLIC_KEY;
    const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
    subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: convertedVapidKey
    });
  }
  

  // Get the server's public key
  // const response = await fetch('./vapidPublicKey');
  // const vapidPublicKey = "test"; //await response.text();
  // Chrome doesn't accept the base64-encoded (string) vapidPublicKey yet
  // urlBase64ToUint8Array() is defined in /tools.js
  // const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

  // Otherwise, subscribe the user (userVisibleOnly allows to specify that we don't plan to
  // send notifications that don't have a visible effect for the user).
  // subscription = await registration.pushManager.subscribe({
  //   userVisibleOnly: true,
  //   // applicationServerKey: convertedVapidKey
  // });

  console.log(subscription);

  await APP().api.fetch("POST", "/push-sub/register", {
    userId: APP().me.id,
    details: subscription,
  });
  alreadySetup = true;

  // fetch('./register', {
  //   method: 'post',
  //   headers: {
  //     'Content-type': 'application/json'
  //   },
  //   body: JSON.stringify({
  //     subscription: subscription
  //   }),
  // });

  // document.getElementById('doIt').onclick = function() {
  //   const payload = document.getElementById('notification-payload').value;
  //   const delay = document.getElementById('notification-delay').value;
  //   const ttl = document.getElementById('notification-ttl').value;

  //   // Ask the server to send the client a notification (for testing purposes, in actual
  //   // applications the push notification is likely going to be generated by some event
  //   // in the server).
  //   fetch('./sendNotification', {
  //     method: 'post',
  //     headers: {
  //       'Content-type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       subscription: subscription,
  //       payload: payload,
  //       delay: delay,
  //       ttl: ttl,
  //     }),
  //   });
  // };

}



function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

