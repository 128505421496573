// import { convertInnardsToElements, el, NestedChildNodeArrayFlattener } from '../../El-Tool/El-Tool';

// import { heliumHaze, isHeliumHazed } from '../../HeliumBase';
// import { deriveDomAnchored, IDomAnchorReport, Reporter } from './DomAnchoredDeriver';
import { LayoutBase } from '@nativescript/core';
import { DerivationManager, SourceBase, DeriverScope } from 'helium-sdx';
import { deriveDomAnchored } from './dom-anchored-deriver';
import { View, remove, children, convertInnardsToNodes } from '../helium';
import { BaseNode, heliumHaze, Innards, InnardsFn, isHeliumHazed } from '../el-tool/el-tool.io';
// import { BaseNode, Innards, InnardsFn, BaseNode } from '../../El-Tool/El-Tool.io';


export class RerenderPlaceholder extends View {
  constructor(id: string) {
    super();
    this.id = id;
    this.height = 0;
    this.width = 0;
  }
}



const REMOVE_NODE = false;
const ADD_NODE = true;

/** A form of deriver management.  Beyond hosting the deriver function, this class
 * also handles freezing and unfreezing derivation when its placeholder is added
 * or removed from the page.  Further, it makes sure to only add, remove, or swap
 * the elements in each rerender if they require it.
 * @param.rerenderFn - A function which returns whatever the most up to date elements
 * for it should be */
export class RerenderReplacer {
  private static nextId = 0;

  private currentRender: BaseNode[];
  private id = "ddx_" + RerenderReplacer.nextId++;
  private placeholder: RerenderPlaceholder;
  protected deriverScope: DeriverScope;
  // protected getAnchorReport: Reporter<IDomAnchorReport>

  constructor(
    protected rerenderFn: InnardsFn,
    protected args: {
			initialRoot?: BaseNode;
      // flattenFn?: NestedChildNodeArrayFlattener;
			placeholder?: RerenderPlaceholder;
    } = {}
  ) {
    this.preConstructHook();
    if (DerivationManager.disabled()) {
      console.log("NO DDX");
      this.currentRender = convertInnardsToNodes(
				// args.initialRoot,
				this.rerenderFn(args.initialRoot || null, { scope: null as any, store: {}}),
				// args.flattenFn
			);

    } else {
      this.placeholder = args.placeholder || new RerenderPlaceholder(this.id);
      const helium = heliumHaze(this.placeholder)._helium;
      helium.rerenderReplacer = this;
      // helium.isReplacer = true;
      this.deriverScope = deriveDomAnchored(this.placeholder, this.derive.bind(this));
    }
  }

  protected preConstructHook() {}

  /** Returns the current render. This render is live and will update automatically. */
  public getRender(): BaseNode[] { return this.currentRender.slice(0); }

  /** Typically called by DerivationManager, if not frozen, this function
   * will update its current render both on the page and locally, then return it. */
	protected firstDeriverRun = true;
  public derive(ddxState: { scope: DeriverScope, store: any }) {
    const parent = this.placeholder.parent as LayoutBase;
    
		const root = (this.firstDeriverRun && this.args.initialRoot) || parent || null;
		this.firstDeriverRun = false;

    const newRender = convertInnardsToNodes(this.rerenderFn(root, ddxState)); //this.args.flattenFn
    console.log(newRender);

    newRender.push(this.placeholder);

    const oldRender = this.currentRender;
    this.currentRender = newRender;

    if (!parent || !oldRender) {
      return;
    } else if (parent instanceof LayoutBase === false) {
      throw new Error("Bad parent type " + typeof parent);
    }

    // console.log("replacing");

    const addRemoveMap = new Map<BaseNode, boolean>();
    oldRender.forEach((node) => addRemoveMap.set(node, REMOVE_NODE));
    newRender.forEach((node) => {
      if (addRemoveMap.has(node)) {
        addRemoveMap.delete(node); // neither added nor removed
      } else {
        addRemoveMap.set(node, ADD_NODE);
      }
    });
    Array.from(addRemoveMap.entries()).forEach(([node, addRemove]) => {
      if (addRemove === REMOVE_NODE) {
        remove(node);
        if (isHeliumHazed(node) && node._helium.rerenderReplacer) {
          node._helium.rerenderReplacer.removeAll();
        }
      }
    });

    const currentNodes = children(parent);
    let parentIndex = Array.from(currentNodes).indexOf(this.placeholder);
    // console.log("parent index", parentIndex, parent);
    let lastNode: BaseNode;
    const length = newRender.length;
    for (let i = 0; i < length; i++) {
      const addMe = newRender[length - 1 - i];
      const current = currentNodes[parentIndex - i];
      if (addMe === current) {
        // console.log("Same items", addMe, current);
        // do nothing
      } else {
        parent.insertChild(addMe, parent.getChildIndex(lastNode));
        if (addRemoveMap.get(addMe) !== ADD_NODE) {
          // console.log("Non-new item", addMe, current);
          heliumHaze(addMe)._helium.moveMutation = true;
        } else {
          // console.log("New item", addMe, current);
          parentIndex++;
        }
      }
      lastNode = addMe;
    }
  }

  /** Removes all elements managed by this replacer from the page */
  public removeAll() {
    this.currentRender.forEach((node) => {
      remove(node);
    });
  }
}













// MOVED FROM RECYCLER FOR CIRCULAR REFERENCE REASONS




export interface ICachedRender {
  sourceVals: Map<SourceBase, any>,
  render: Innards
};


export class RenderRecycler extends RerenderReplacer {
  protected preConstructHook() {
    const renders: Array<ICachedRender> = []
    const rootRenderFn = this.rerenderFn;
    this.rerenderFn = (root, ddxState) => {
      let cachedRender = renders.find((prevRender) => {
        const sourceVals = Array.from(prevRender.sourceVals.entries());
        for (const [source, val] of sourceVals) {
          if (val !== source.peek()) { return false; }
        }
        // make sure dependencies are updated
        for (const [source, val] of sourceVals) { source.get(); }
        return true;
      });

      // TODO: Make sure that the render is anchored to the cache, not
      // the recycler
      if (!cachedRender) {
        renders.push(cachedRender = {
          sourceVals: new Map(),
          render: rootRenderFn(root, ddxState),
        });
        const sources = this.deriverScope.getSourceDependencies();
        sources.forEach((source) => cachedRender!.sourceVals.set(source, source.peek()));
      } else {
        console.log("Reusing cached render!", cachedRender);
      }

      return cachedRender.render;
    }
  }
}


/** Shorthand for creating a new RenderRecycler class.  Use this when you want to
 * cache previous renders.
 * @importance 17
 * @warning This functions stops garbage collection of old views by caching them.  Only use this
 * function for sections of your UI which have very few permutations each with large amounts of content.
 * @eg const view = source("bert");\
 * const viewEl = div("View", recycle(\
 *   // each of these will only be rendered once\
 *   switch (view.get()) {\
 *     case "bert": return renderLargeMarkdown("berts-autobiography.md");\
 *     case "ernie": return renderLargeMarkdown("ernies-autobiography.md");\
 *   }\
 * ));
 */
export function recycle(renderFn: () => Innards) {
  const replacer = new RenderRecycler(renderFn);
  return replacer.getRender();
}
