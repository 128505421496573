import { DeepPartial, SelectNullable, SelectRequired, MergeUnion } from "../misc/utility-types";

export const RoleIdPrefix = "rol";

export const RoleTypeList = [
  "contact", "app-invite",
] as const;
export type RoleType = typeof RoleTypeList[number];

export const RoleStatusList = [
  "pending", "accepted", "interested", "going", "archived"
] as const;
export type RoleStatus = typeof RoleStatusList[number];

export const RoleDetailsExamples = [{
  msg: {
    owner: "Hey!",
    target: "Howdy"
  }
},{
  for: "Alex",
  // hash: "abc123",
  key: "lala",
  acceptedById: "u:alex"
}];

export type RoleDetails = MergeUnion<DeepPartial<typeof RoleDetailsExamples[number]>>



export const ExampleRole = {
  id: RoleIdPrefix + ":_1",
  createdAt: new Date(),
  endedAt: null as Date | null,
  ownerId: "usr:112-123", 
  targetId: "usr:2", 
  contextId: "gthr:1", 
  type: "contact" as RoleType,
  status: "pending" as RoleStatus,
  details: RoleDetailsExamples[0] as RoleDetails
}

export type RoleModel = SelectNullable<typeof ExampleRole, "endedAt" | "contextId" | "details">;

export type RoleArgs = SelectRequired<RoleModel, "ownerId" | "targetId" | "type" | "status">