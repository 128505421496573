import "./site.scss";

import { ClientENV } from "../../client/src/ENV";
import { SET_TARGET } from "../../helium-star/src";
import { renderApp } from "../shared/components/app/app.ui";
import { APP } from "../shared/state/app.state";
import { renderJoinFromInvite } from "../shared/components/join-from-invite";
import { setupPushNotifications } from "./push-note-setup";


ClientENV.setup({
  // localApiPath: `http://localhost:3333`
  localApiPath: "https://dev-api.togather.earth"
});

(window)["RobinHood-License for helium-sdx @ 50M/2K"] = "resolved"
SET_TARGET("HTML");

const site = renderSite();
document.body.append(site);



export function renderSite() {
  const out = document.createElement("div");
  out.className = "Site";
  out.appendChild(renderApp() as HTMLElement);
  return out;
}

const params = new URLSearchParams(location.search);
const joinParam = params.get("join");
if (joinParam) {
  APP().overlay = renderJoinFromInvite({joinParam});
}

// document.addEventListener("touchmove", function(e){ e.preventDefault(); }, false);
// document.body.addEventListener("touchmove", (e) => e.preventDefault());

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  let deferredPrompt = e;
  console.log(e);
  alert("CAN INSTALL");

  // addBtn.addEventListener('click', () => {
  //   // hide our user interface that shows our A2HS button
  //   addBtn.style.display = 'none';
  //   // Show the prompt
  //   deferredPrompt.prompt();
  //   // Wait for the user to respond to the prompt
  //   deferredPrompt.userChoice.then((choiceResult) => {
  //     if (choiceResult.outcome === 'accepted') {
  //       console.log('User accepted the A2HS prompt');
  //     } else {
  //       console.log('User dismissed the A2HS prompt');
  //     }
  //     deferredPrompt = null;
  //   });
  // });
});