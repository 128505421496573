import { SelectNullable, SelectRequired } from "../misc/utility-types";

export const UserIdPrefix = "user";

export const UserNameDisplayModeList = [
  "f", "f(a)", "fl", "fl(a)", "fml", "fml(a)",
  "m", "m(a)", "ml", "ml(a)",
  "l", "l(a)",
  "a", "a(f)", "a(fl)", "a(fml)",
  "a(m)", "a(ml)", "a(l)"
] as const;
export type UserNameDisplayMode = typeof UserNameDisplayModeList[number];

export const ExampleUserName = {
  first: "Seph",
  last: "Reed",
  middle: "Henry",
  alias: "Thumbz",
  displayMode: "f(a)" as UserNameDisplayMode,
}
export type UserName = Partial<typeof ExampleUserName>;

export const ExampleUserAvatar = {
  emoji: "⛵️",
  color1: "turquoise",
  color2: "#95B",
};
export type UserAvatar = Partial<typeof ExampleUserAvatar>



export const ExampleUser = {
  id: UserIdPrefix + ":_1",
  createdAt: new Date(),
  updatedAt: new Date(),
  name: ExampleUserName as UserName,
  birthday: new Date("July 1st 1990"),
  phoneNum: 7375295031,
  avatar: ExampleUserAvatar as UserAvatar,
  passwordHash: "abc123"
}


export type UserModel = SelectNullable<typeof ExampleUser, "birthday" | "phoneNum" | "avatar">;
export type UserArgs = SelectRequired<UserModel, "name" | "passwordHash">;
