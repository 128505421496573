import { apiData } from "./apiDataType";
import { MIDDLEWARE_TEST_DATA } from "../models/__tests/test-data";



export const InviteAPI = {
  assertWithBacker: apiData({
    path: "/invite/assert-with-backer",
    payloadEg: {
      gatherId: "gthr:party",
      toId: "usr:target",
      fromId: "usr:sharer"
    },
    responseEg: {
      invite: MIDDLEWARE_TEST_DATA.invites[0],
      pairing: MIDDLEWARE_TEST_DATA.pairings[0]
    },
  })
}