import { View } from "@nativescript/core";
import { DeriverScope } from "helium-sdx";
import { RerenderReplacer } from "../derivations/rerender-replacer";
import { EventData } from "@nativescript/core/data/observable";


export interface IViewProps {
	// dock?: "left" | "top" | "right" | "bottom";
	class?: string;
	ddxClass?: () => string;
	id?: string;
	flexGrow?: number;
	// attr?: {[key: string]: string};
	// style?: {[key: string]: string} | string;
	on?: {[key: string]: (event?: EventData) => any};
	onPush?: (event?: EventData) => any;
	bgUrl?: string;
	ref?: (view: View) => void;
	// overflow?: "hidden" | "visible";
	pointerEvents?: "none" | "all";
	visibility?: "visible" | "hidden" | "collapse";
	row?: number;
	rowSpan?: number;
	col?: number;
	colSpan?: number;
}


export type LabelInnards = string | number | null | undefined | false | void;
export type PrimitiveInnards = LabelInnards | LabelInnards[];
export type BaseNode = View;
export type SingleItemInnard = LabelInnards | BaseNode;


export type Child = PrimitiveInnards | View | (() => Child);
export type Innards = Child | Array<Child> | InnardsFn;


// export type StaticInnard = PrimitiveInnard | (Array<StaticInnard> | ((...args: RenderFnArgs) => StaticInnard));
// export type SingleInnard = StaticInnard ;


// export type ManyStaticInnards = SingleInnard | Array<SingleInnard>;
// export type Innards = ManyStaticInnards | ((...args: RenderFnArgs) => ManyStaticInnards);
// // export type NonPrimativeInnards = BaseNode | Array<SingleInnard> | ((...args: RenderFnArgs) => ManyStaticInnards);

export type RenderFnArgs = [BaseNode | null, { scope: DeriverScope; store: any }]
export type InnardsFn = ((...args: RenderFnArgs) => Innards)




export type IHeliumHazed<TYPE> = TYPE & {
	_helium: {
		frozen?: boolean,
		freeze?: Array<() => any>,
		unfreeze?: Array<() => any>,
		freezeListeners?: {
			freeze: () => any;
			unfreeze: () => any;
		}
		dirty?: boolean,
		rerenderReplacer?: RerenderReplacer,
		moveMutation?: boolean,
	}
}
export function isHeliumHazed<TYPE>(checkMe: TYPE): checkMe is IHeliumHazed<TYPE> {
	return !!(checkMe && (checkMe as any)._helium);
}
export function heliumHaze<TYPE>(hazeMe: TYPE) {
	if (isHeliumHazed(hazeMe) === false) {
		(hazeMe as any)._helium = {
			frozen: true,
		};
	}
	return hazeMe as IHeliumHazed<TYPE>;
}




export function isView(testMe: any): testMe is View {
	return testMe instanceof View;
}

// export function isRenderer(testMe: any): testMe is Renderer {
// 	return testMe instanceof Renderer;
// }

// export function isObservable(testMe: any): testMe is Observable<any> {
// 	return testMe instanceof Observable;
// }

export function isProps(testMe: any): testMe is IViewProps {
	return typeof testMe === "object" && !Array.isArray(testMe) && !isView(testMe);
}

// function _isGenFunction(testMe: Innards): testMe is () => Innards {
// 	return !isRenderer(testMe) && typeof testMe === "function";
// }