import { Source } from "helium-sdx";
import { grid, textField, TextFieldProps } from "../../../helium-star/src";
import { HeliumElement, SingleItemInnard } from "../../../helium-star/src/el-tool/el-tool.io";


export function renderLabeledInput(args: {
  sizing?: [number, number],
  label: SingleItemInnard,
  input?: TextFieldProps
}) {
  const input = textField({
    ...args.input,
    col: 2,
  });
  return grid({
    rows: "auto",
    columns: (args.sizing || [1,3]).map((it) => it + "*").join(),
    ref: (ref) => (ref as any).value = input.value,
  }, [
    args.label,
    input
  ]) as HeliumElement & { value: Source<string> }
}



export type LabeledItemArgs<T extends HeliumElement> = {
  sizing?: [number, number],
  label: SingleItemInnard,
  item: T
}

export function renderLabeledItem<T extends HeliumElement>(args: LabeledItemArgs<T>) {
  return grid({
    rows: "auto",
    columns: (args.sizing || [1,3]).map((it) => it + "*").join(),
    ref: (ref) => (ref as any).item = args.item,
  }, [
    args.label,
    args.item
  ]) as HeliumElement & { item: T }
}