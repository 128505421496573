


export class PushNotifications {
  protected listeners: Array<(note: string) => any> = [];

  public onNotification(cb: (note: string) => any) {
    this.listeners.push(cb);
    return {
      dispose: () => { this.listeners = this.listeners.filter((it) => it !== cb); }
    }
  }

  public consumeNote(note: string) {
    this.listeners.forEach((cb) => cb(note));
  }
}