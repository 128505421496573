import { Entity, EntityManager, SourceRepo } from "helium-source-repo";
import { ExampleNotification, NotificationArgs, NotificationModel} from "../../../middleware/models/notification.model";
// import { NotificationAPI } from "../../../middleware/endpoints/notification.apiDatas";
import { commonPathSetups } from "./ents-util";
import { UserEntity } from "./User.ents";
import { DerivationManager, Sourcified } from "helium-sdx";
import { APP } from "../client";


export class NotificationManager<
  ENT extends NotificationEntity = NotificationEntity
> extends EntityManager<NotificationEntity> {
  initiateDefaultArgs(): void {
    this.defaultArgs = {
      repo: new NotificationRepo(),
      createEntity: (args) => new NotificationEntity(args),
    };
  }
}



export class NotificationEntity 
  extends Entity<NotificationModel, "id", NotificationArgs> 
  implements Record<keyof NotificationModel, any> 
{
  public get id() { return this.getId(); }
  public get createdAt() { return this.getProp("createdAt"); }
  public get userId() { return this.getProp("userId"); }
  public get type() { return this.getProp("type"); }
  public get seenAt() { return this.getProp("seenAt"); }
  public get archivedAt() { return this.getProp("archivedAt"); }
  public get contextId() { return this.getProp("contextId"); } 

  public get user() {
    if (this.userId) { return APP().ents.user.get(this.userId); }
    return null;
  }

  public get context() {
    const self = this;
    return {
      get asRole() { return APP().ents.role.get(self.contextId!); },
      get asInvite() { return APP().ents.invite.get(self.contextId!); },
      get asGather() { return APP().ents.gather.get(self.contextId!); }
    }
  }
}



export class NotificationRepo
extends SourceRepo<NotificationModel, "id", NotificationArgs> {
  constructor() {
    super({
      idPropName: "id",
      repoId: "notification",
      gqlPropTypes: {
        type: "enum"
      },
      ...commonPathSetups("notification"),
      allFieldsNames: Object.keys(ExampleNotification) as any
    }, {
      getJoinFilter: (key, item) => {
        switch(key) {
          case "User": return { repo: APP().ents.user.repo, where: { id: item.userId }};
          case "ContextRole": return { repo: APP().ents.role.repo, where: { id: item.contextId }};
          case "ContextInvite": return { repo: APP().ents.invite.repo, where: { id: item.contextId }};
        }
        throw new Error("unknown name");
      },
      // whereRules: {
      //   endedAt: null
      // }
    })
  }
}