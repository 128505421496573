import { GatherEntity, NotificationEntity } from "../../../client/src/ents";
import { stack, button, grid, label, labelH2, labelH3, wrap } from "../../../helium-star/src";
import { APP } from "../state/app.state";
import { renderInvitesEditor } from "./invites-editor";


APP.registerViewSetup({
  title: null,
  rootwardsStyle: "back",
  matchCheck: (view) => view.startsWith("gathering/view/"),
})

export function renderGathering(id: string) {
  return stack("Gathering", () => {
    const gather = APP().ents.gather.get(id);
    if (gather._is.loading) {
      return "Loading...";
    } else {
      // gather.reload();
    }
    
    // else if (gather.timeSinceLastRefresh > 2 * 1000) {
    //   gather.reload();
    // }

    const extendable = () => gather.createdBy === APP().me.id || gather.extendable;
    const createdByYou = () => gather.createdBy === APP().me.id;
    const archived = () => !!gather.archivedAt;

    return [
      () => {
        console.log("RUN?");
        const archive = (notif: NotificationEntity) => {
          console.log("archive", notif);
          notif && !notif.seenAt && notif.update({seenAt: Date.now() as any})
        }
        gather.myInvite.value?.myNotifys?.getFresh().forEach(archive);
        gather.myNotifs?.getFresh().forEach(archive);
      },
      () => archived() && stack("card colors-warn", "This is an archived gathering.  Changes can no longer be made to it."),

      grid("li-card", { rows: "auto,*", columns: "*,auto"},[
        stack({ rowSpan: 2, colSpan: 2}, [
          labelH3("text-center", gather.name),
          gather.info,
        ]),
        () => createdByYou() && !archived() && button("m-0 p-5",{
          row: 1,
          col: 2,
          onPush: () => APP().view = `gathering/edit/${gather.id}`,
          innards: "✏️"
        })
      ]),
      
      () => !archived() && !createdByYou() && renderGatheringResponseInput(gather),

      stack("li-card p-0", [
        () => !createdByYou() && stack("line-item", { orientation: "horizontal" },[
          "Invited By: ",
          "TODO"
          // stack("Going", () => {
          //   const invites = gather.invitesFor(APP().me);
          //   if (!invites.length) { return "--"; }
          //   return invites.map((role) => role.owner.displayName).join(", ");
          // }),
        ]),

        stack("line-item", { orientation: "horizontal" },[
          "Going: ",
          stack("Going", () => {
            if (!gather.goingList.length) {
              return "--";
            }
            return gather.goingList.map(({user}) => user.displayName).join(", ");
          }),
        ]),

        stack("line-item", { orientation: "horizontal" },[
          "Interested: ",
          stack("Interested", () => {
            if (!gather.interestedList.length) {
              return "--";
            }
            return gather.interestedList.map(({user}) => user.displayName).join(", ");
          }),
        ]),

        () => extendable() && stack("line-item", { orientation: "horizontal" },[
          "Your Invitees: ",
          stack(() => {
            const invitees = APP().me.invitesSharedForGathering(gather);
            if (!invitees.length) {
              return "--"
            }
            return invitees.map(({user}) => user.displayName).join(", ");
          }),
        ]),
      ]),

      () => extendable() && !archived() && button("Invite", {
        onPush: () => APP().overlay = renderInvitesEditor({gather}),
        innards: "Share Invites"
      }),

      () => createdByYou() && !archived() && button("colors-error", {
        onPush: () => APP().overlay = stack("card", [
          "Are you sure you want to archive this event?",
          button({
            innards: "Cancel",
            onPush: () => APP().overlay = null,
          }),
          button("colors-error", {
            innards: "Archive",
            onPush: async () => {
              await gather.update({
                archivedAt: Date.now() as any
              });   
              APP().overlay = null;
              // APP().view = "gathers";
            }
          }),
        ]),
        innards: "Archive Event"
      })
    ]
  })
}



function renderGatheringResponseInput(gather: GatherEntity) {
  const invite = APP().ents.invite.find.one({ toId: APP().me.id, gatherId: gather.id })
  if (!invite) {
    return "You haven't been invited to this gather... how did you get here?"
  }

  // if (invite.response !== "pending") {
  //   return `You responded: ${invite.status}`
  // }

  return [
    label("text-center", "Respond to invite:"),
    grid("InviteResponses", { 
      rows: "auto",
      columns: "*,*,*",
    },[
      button({
        ddxClass: () => invite.response === "going" && "btn-highlighted",
        onPush: () => invite.update({response: "going"}),
        innards: "Going",
      }),
      button({
        ddxClass: () => invite.response === "interested" && "btn-highlighted",
        onPush: () => invite.update({response: "interested"}),
        innards: "Interested",
      }),
      button({
        onPush: () => invite.update({archivedAt: new Date()}),
        innards: "Archive",
      }),
    ])
  ]
}

