import { RoleEntity } from "../../../client/src/ents";
import { onPush } from "../../../helium-star/node_modules/helium-ui/dist";
import { button, label, list, stack } from "../../../helium-star/src";
import { APP } from "../state/app.state";
import { Style } from "../styling/common";
import { renderLabeledInput } from "./labeled-input";
import { renderSaveEditsButton } from "./save-edits-button";
import { renderEmojiAvatar } from "./emoji-avatar";

APP.registerViewSetup({
  rootwardsStyle: "drawer",
  matchCheck: "app-invites",
  title: "Your App Invites"
})

export function renderAppInvites() {
  return stack("AppInvites", () => {
    const { appInvites } = APP().me;
    return [
      stack("li-card p-0", [
        label(Style.cardHeader, "Pending"),
        stack("messageOrElements", () => {
          const pending = appInvites.filter((it) => it.status === "pending").map((role) => 
            stack({
              innards: "Invite for: " + role.details.for!,
              onPush: () => APP().overlay = renderAppInviteDetails(role)
            })
          );
          if (!pending.length) { return "No app invites pending"; }
          return list(pending);
        }),
      ]),

      button({
        innards: "Create New App Invite",
        onPush: () => APP().overlay = renderAppInviteCreator()
      }),

      stack("li-card p-0", [
        label(Style.cardHeader, "Accepted"),
        stack("messageOrElements", () => {
          const accepted = appInvites.filter((it) => it.status === "accepted").map((role) => 
            stack({
              orientation: "horizontal",
              innards: () => {
                const user = role.target;
                return !user ? null : [
                  renderEmojiAvatar(() => ({
                    size: "1xl",
                    ...user.avatar.toObject()
                  })),
                  stack([
                    user.displayName,
                    "Invite for: " + role.details.for
                  ]),
                ]
              }
            })
          );
          if (!accepted.length) { return "No app invites accepted"; }
          return list(accepted);
        }),
      ]),
    ]
  })
}




function renderAppInviteCreator() {
  const createMe = APP().ents.role.new({
    type: "app-invite",
    ownerId: APP().me.id,
    targetId: "unclaimed",
    status: "pending",
  })
  return stack("AppInviteCreator", [
    renderInviteBanWarning(),
    stack("card", [
      label("text-center text-sm", "Create Invite"),
      renderLabeledInput({
        sizing: [2, 7],
        label: "For:",
        input: {
          innards: createMe.details.getSource("for"),
        }
      })
    ]),
    "",
    renderSaveEditsButton({
      text: "Save",
      editor: createMe,
      extraCheck: () => !!createMe.details.for,
      then: () => {
        APP().overlay = renderAppInviteDetails(createMe)
      }
    })
  ])
}

function renderAppInviteDetails(role: RoleEntity) {
  return stack("AppInviteDetails", [
    renderInviteBanWarning(),
    stack("text-center card", [
      () => label(Style.cardHeader, "Invite token for: " + role.details.for),
      "Please share with them the following link...",
      "",
      () => label("text-sm", `togather.earth?join=${role.id}::${role.details.key!}`),
      button({
        innards: "Copy Link",
        onPush: () => null
      })
    ])
  ]);
}

function renderInviteBanWarning() {
  return stack("card colors-warn", [
    label("text-lg", "Heads up!"),
    label("text-sm", "If you're found inviting any bots or bad actors, your account will be banned "
      + "and everyone in your invite chain will be temp banned until someone else can vouch for them"
    )
  ]);
}