import { DdxSource, DerivationManager, Sourcified } from "helium-sdx";
import { Entity, EntityManager, jit, SourceRepo } from "helium-source-repo";
import { ExampleUser, UserArgs, UserModel} from "../../../middleware/models/user.model";
import { APP } from "../client";
import { jitDdxSource } from "../utils/jit-ddx-source";
import { Loading } from "../utils/loading";
import { commonPathSetups } from "./ents-util";
import { GatherEntity } from "./Gather.ents";
import { InviteEntity } from "./Invite.ents";
import { RoleEntity } from "./Role.ents";

export class UserManager<
  ENT extends UserEntity = UserEntity
> extends EntityManager<UserEntity> {
  initiateDefaultArgs(): void {
    this.defaultArgs = {
      repo: new SourceRepo({
        idPropName: "id" as never,
        repoId: "user",
        ...commonPathSetups("user"),
        allFieldsNames: Object.keys(ExampleUser)
      }),
      createEntity: (args) => new UserEntity(args),
    } as any;
  }
}



export class UserEntity 
extends Entity<UserModel, "id", UserArgs> 
implements Partial<UserModel> {
  public get id() { return this.getId(); }
  public get createdAt() { return this.getProp("createdAt"); }
  public get updatedAt () { return this.getProp("updatedAt"); }
  public get name() { return this.getProp("name", {}) as Sourcified<UserModel["name"]>; } 
  public get birthday() { return this.getProp("birthday"); }
  public get phoneNum() { return this.getProp("phoneNum"); }
  public get avatar() { return this.getProp("avatar", {}) as Sourcified<UserModel["avatar"]>; }
  

  public get displayName() { 
    return [
      this.name.first,
      this.name.last,
      this.name.alias && `(${this.name.alias})`
    ].filter((it) => !!it).join(" ") 
  }

  // ----- Query Handlers --------

  public get contactItemsHandler() {
    return jit(this, "contactItemsHandler", () => {
      const { gather, role, user, queryHandler } = APP().join;
      return queryHandler({
        "o: Roles": role({ where: { type: "contact", ownerId: this.id, endedAt: null }}, {
          Target: user({singular: true})
        }),
        "t: Roles": role({ where: { type: "contact", targetId: this.id, endedAt: null }}, {
          Owner: user({singular: true})
        }),
      })
    }) 
  }

  public get contactItems(): "Loading" | Array<{role: RoleEntity, user: UserEntity}> {
    return jitDdxSource(this, "contacts", () => {       
      const resp = this.contactItemsHandler.value; 
      if (!resp) { return "Loading"; }

      const out = !resp ? [] : [
        ...resp["o: Roles"].map(({item, Target}) => [item, Target!.item]),
        ...resp["t: Roles"].map(({item, Owner}) => [item, Owner!.item]),
      ].map(([role, user]) => ({role, user}));
      
      return out as any;
    })
  }

  public get contacts() {
    const items = this.contactItems;
    return Array.isArray(items) ? items.filter(({role}) => role.status === "accepted") : items;
  }

  public get contactRequests() {
    const items = this.contactItems;
    return Array.isArray(items) ? items.filter(({role}) => role.targetId === this.id && role.status === "pending") : items;
  }
 
  public get contactSuggestions() {
    return jitDdxSource(this, "contactSuggestions", () => {
      const handler = APP().ents.user.getAll.handler();
      
      console.log(handler);
      const resp = handler.value;
      if (!resp) { return "Loading"; }

      return !resp ? [] : resp.filter((user) => {
        if (user === this) { return false; }

        const items = this.contactItems;
        if (items === "Loading") { return false; }
        const inOtherCategory = items.some((item) => {
          return (item.user === user
            && (
              item.role.ownerId !== this.id
              || item.role.status !== "pending"
            )
          );
        });
        return !inOtherCategory;
      })
    })
  }

  public getConnection(user: UserEntity) {
    const items = this.contactItems;
    return Array.isArray(items) ? items.find((it) => it.role.ownerId === user.id || it.role.targetId === user.id) : items;
  }


  public get allGathers() {
    const { invites, gathersHosting } = this;
    return {
      refresh() {
        invites.refresh();
        gathersHosting.refresh();
      },
      get loading() { 
        return (invites as any).loading || gathersHosting.loading
      },
      get msSinceLastUpdate() {
        return Math.max(invites.msSinceLastUpdate, gathersHosting.msSinceLastUpdate);
      },
      get items() {
        return [
          ...invites.getFresh(),
          ...gathersHosting.getFresh()!.map((gather) => ({ invite: null, gather, backers: null }))
        ]
      }
    }
  }

  public get openGathers() {
    const handler = this.allGathers;
    return {
      ...handler,
      get items() { return handler.items.filter((it) => !it.gather.archivedAt)}
    };
  }


  public get invites() {
    return jit(this, "invites", () => {
      const { gather, invite, queryHandler, pairing } = APP().join;
      const handler = queryHandler({
        "Invites": invite({ where: { toId: this.id, archivedAt: null }}, {
          Gather: gather({singular: true}),
          Backers: pairing()
        }),
      });
      return handler.createSubHandler({
        convertResponse: (resp) => !resp ? [] : resp.Invites.map((it) => ({
          invite: it.item,
          gather: it.Gather!.item,
          backers: it.Backers.map(({item}) => item)
        }))
      });
    })
  }

  public get gathersHosting() {
    return APP().ents.gather.find.handler({where: { createdBy: this.id }});
  }

  public get gathersGoing() {
    return jit(this, "gathersGoing", () => 
      this.invites.createSubHandler({ 
        convertResponse: (resp) => resp?.filter((it) => it.invite.response === "going") || []
      })
    )
  }

  public get gathersInterested() {
    return jit(this, "gathersInterested", () => 
      this.invites.createSubHandler({ 
        convertResponse: (resp) => resp?.filter((it) => it.invite.response === "interested") || []
      })
    )
  }

  public get gathersArchived() {
    const pkg = this.allGathers;
    return {
      handler: pkg,
      get items() {
        return pkg.items.filter((it) => !!it.gather.archivedAt);
      }
    };
  }

  
  public invitesSharedForGathering(gather: GatherEntity) {
    return jitDdxSource(this, `invitesShared-${gather.id}`, () => 
      gather.invitees.filter(({backers}) => backers.some(({fromId}) => fromId === this.id))
    )
  }

  public suggestedInvitesForGathering(gather: GatherEntity) {
    return jitDdxSource(this, `invitesSuggested-${gather.id}`, () => {
      const { contacts } = this;
      const invitees = this.invitesSharedForGathering(gather);
      if (contacts === "Loading") { return Loading; }
      return contacts.map<{ user: UserEntity}>((contact) => {
        if (contact.user.id === gather.createdBy) {
          return undefined;
        }
        const yourInvite = invitees.find((invite) => 
          contact.user === invite.user
          && invite.backers.some(({fromId}) => fromId === this.id)
        );
        if (yourInvite) { return undefined as any; }
        return {
          user: contact.user,
        }
      }).filter(it => !!it);
    })
  }

  public get appInvites() {
    return jitDdxSource(this, "appInvites", () => 
      APP().ents.role.find({ ownerId: this.id, type: "app-invite" })
    )
  }

  public get notifications() {
    return jit(this, "notifs", () => 
      APP().ents.notify.find.handler({ where: { userId: this.id}})
    )
  }

  public get unseenNotifys() {
    return jit(this, "unseenNotifs", () => 
      this.notifications.createSubHandler({
        convertResponse: (resp) => resp?.filter((it) => !it.seenAt) || [],
      })
    );
  } 
}  