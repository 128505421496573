import { ddxCache, ddxSource } from "helium-sdx";
import { grid, label } from "../../../helium-star/src";


export function renderEmojiAvatar(cb: () => {
    size?: "7xl" | "6xl" | "3xl" | "2xl" | "1xl" | "sm" | "xs"
    emoji?: string, 
    color1?: string, 
    color2?: string 
  }) {
  const cache = ddxSource(cb);
  return grid("bg-testColor rounded-full", {
    rows: "auto",
    columns: "*",
    innards: () => label("self-center-center", {
      ddxClass: () => `text-${cache.get().size || "3xl"}`,
      innards: cache.get().emoji || "⛵️"
    }),
    ddx: (ref) => {
      let { color1, color2 } = cache.get();
      if (!color1 && !color2) {
        color1 = "turquoise";
        color2 = "#95B";
      }
      if (!color1 || !color2) {
        ref.style.background = color1 || color2
      } else {
        ref.style.background = `linear-gradient(to bottom right, ${color1}, ${color2})`
      }
    },
  })
}