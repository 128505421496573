import { TogetherClient } from "../../../client/src/client";
import { Sourcify, noDeps, Source } from "helium-sdx";
import { HeliumElement } from "../../../helium-star/src/el-tool/el-tool.io";
import { syncToStorage } from "../../../helium-star/src";

export const ViewList = [
  "alt-logins",
  "settings",
  "profile",
  "gatherings",
  "gatherings/archived",
  "gatherings/featured",
  "gatherings/going",
  "gatherings/hosting",
  "gatherings/interested",
  // "gatherings/create-new",
  "invites",
  "app-invites",
  "contacts/known",
  "contacts/requests",
  "contacts/suggestions",
  "contacts/find-a-person",
  "notifications"
] as const;

export type ViewType = typeof ViewList[number];

export type ViewSetup = {
  rootwardsStyle: "none" | "back" | "drawer" | "close",
  onRootwards?: "back" | "drawer" | "close" | { view: string } | (() => any),
  matchCheck: string | ((view: string) => boolean),
  title: null | string | ((view: string) => string),
};

let startView = "start";
startView = "settings";
// startView = "contacts";
// startView = "invites";
// startView = "app-invites";
// let startView = "notifications";
// let startView = "alt-logins";

export class SharedUIAppState extends TogetherClient {
  public static singleton: SharedUIAppState;
  public static viewSetups = [] as Array<ViewSetup>

  constructor() {
    super();
    SharedUIAppState.singleton = this;
    syncToStorage("loginState", this.api.loginState);  
  }
  public _store = Sourcify({
    view: startView,
    contactsView: 0,
    invitesView: 0,
    viewUser: null as any as string,
    devDash: false,
    setBgToAvatarColors: true,
    drawerOpen: false,
  })

  
  // public get lastView() { return this.viewHistory[this.viewHistory.length -1]; }
  
  protected viewHistory: string[] = [];
  public get view() { return this._store.view; }
  public set view(val) { 
    noDeps(() => this.viewHistory.push(this.view));
    this._store.view = val; 
  }

  public replaceView(val: string) {
    this._store.view = val; 
  }

  public goToLastView() {
    this._store.view = this.viewHistory.pop() || "gatherings";
  }

  protected _overlay = new Source<HeliumElement>();
  public get overlay() { return this._overlay.get(); }
  public set overlay(val) { this._overlay.set(val); }

  public get drawerOpen() { return this._store.drawerOpen; }
  public set drawerOpen(val) { this._store.drawerOpen = val; }

  public get currentViewSetup() {
    const out: ViewSetup = SharedUIAppState.viewSetups.find(({matchCheck}) => {
      if (typeof matchCheck === "string") {
        return matchCheck === this.view;
      }
      return matchCheck(this.view);
    }) || {
      rootwardsStyle: "drawer",
      onRootwards: "drawer",
      matchCheck: () => true,
      title: null,
    };
    return out;
  }
}

export const APP = Object.assign(
  () => { 
    if (!SharedUIAppState.singleton) {
      noDeps(() => new SharedUIAppState());
    }
    return SharedUIAppState.singleton;
  },
  { 
    registerViewSetup(addMe: ViewSetup) {
      SharedUIAppState.viewSetups.push(addMe);
    }
  }
); 

if (typeof window !== "undefined") {
  (window as any).APP = APP;
}
