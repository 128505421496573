import { button, stack } from "../../../helium-star/src";
import { APP, ViewType } from "../state/app.state";


function link(to: ViewType, innards: string, extraClass: string = "") {
  return button(`btn-unstyled p-10 text-left ${extraClass}`, {
    ddxClass: () => APP().view === to && "btn-highlighted", 
    onPush: () => {
      APP().view = to;
      APP().drawerOpen = false;
    },
    innards
  })
}

export function renderNavList() {
  return stack("NavList", [
    link("app-invites", "App Invites"),
    "-----------------",
    link("settings", "Settings"),
    link("profile", "Profile"),
    link("alt-logins", "Alt Accounts"),
    "-----------------",
    link("contacts/known", "Contacts", "font-bold"),
    link("contacts/requests", "Requests", "pl-20"),
    link("contacts/suggestions", "Suggestions", "pl-20"),
    // link("contacts/add", "Add"),
    // link("contacts/find-a-person", "Find a Person", "pl-20"),
    "-----------------",
    link("gatherings", "Gatherings", "font-bold"),
    link("gatherings/hosting", "Hosting", "pl-20"),
    link("gatherings/going", "Going", "pl-20"),
    link("gatherings/interested", "Interested", "pl-20"),
    link("gatherings/archived", "Archived", "pl-20"),
    // link("gatherings/create-new", "Create New", "pl-20"),
    "-----------------",
    () => {
      const numNotes = APP().me.notifications.loading ? null : APP().me.unseenNotifys.getFresh().length;
      return link("notifications", `Notifications${numNotes !== null ? ` (${numNotes})` : ""}`);
    }
  ])
}