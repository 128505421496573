import { deriveAnchorless } from "helium-sdx";
import { ApplicationSettings } from "@nativescript/core"


export type SourcifiedObj<T> = { toObject(): T; overwrite(obj: T): void}
export function syncToStorage<T extends object>(id: string, src: SourcifiedObj<T>) {
  const startingVal = ApplicationSettings.getString(id);
  if (startingVal) {
    src.overwrite(JSON.parse(startingVal));
  }
  let skipOne = !!startingVal;
  deriveAnchorless(() => {
    const currentVal = src.toObject();
    if (skipOne) {
      return skipOne = false;
    }
    ApplicationSettings.setString(id, JSON.stringify(currentVal));
  })
}