import { button, list, stack } from "../../../helium-star/src";
import { APP } from "../state/app.state";
import { renderEmojiAvatar } from "./emoji-avatar";
import { renderLogIn } from "./login";


APP.registerViewSetup({
  rootwardsStyle: "back",
  matchCheck: "alt-logins",
  title: "Alt Accounts"
})


export function renderAltLogins() {
  return stack([
    () => {
      const altLogins = APP().api.loginState.alternates.toObject();
      if (altLogins.some((it) => it.userId === APP().me.id) === false) {
        altLogins.push(APP().api.loginState.current);
      }
      return list(altLogins.map(({userId, token}) => 
        stack("li-card", {
          orientation: "horizontal",
          onPush: () => APP().api.loginState.upsert("current", { userId, token }),
          ddxClass: () => userId === APP().me.id && "li-highlighted",
          innards: () => {
            const user = APP().ents.user.get(userId);
            return [
              renderEmojiAvatar(() => ({
                size: "3xl",
                ...user.avatar
              })),
              user.displayName
            ]
          }
        })
      ))
    },
    button("btn-highlighted", {
      onPush: () => {
        APP().overlay = renderLogIn("add-alt");
      },
      innards: "Add New Alt Account"
    }),
  ])
}