import { button, debugDeriver, labelH2, stack } from "../../../helium-star/src";
import { setupPushNotifications } from "../../html/push-note-setup";
import { APP } from "../state/app.state";
import { renderDevDash } from "./dev-dash";
import { renderEmojiAvatar } from "./emoji-avatar";


APP.registerViewSetup({
  title: null,
  matchCheck: "settings",
  rootwardsStyle: "close",
  onRootwards: "back",
})

export function renderMainSettings() {    
  return stack("MainSettings", [
    stack("CurrentUser text-center", () => [
        // "Logged in as",
        renderEmojiAvatar(() => APP().me && APP().me.avatar.toObject()),
        labelH2([APP().me.displayName]),
    ]),

    // button("UpdateProfile", {
    //   onPush: () => APP().view = "profile-settings",
    //   innards: "Update Profile"
    // }),

    // button({
    //   innards: "App Invites",
    //   onPush: () => APP().view = "app-invites",
    // }),

    button({
      onPush: () => {
        if (Notification.permission === "granted") {
          console.log(new Notification("Hi there!"));
          return;
        }
        Notification.requestPermission().then((result) => {
          if (result === 'granted') {
            console.log(new Notification("Notify!"));
          }
        });
      },
      innards: "Enable Notifications"
    }),

    button({
      onPush: () => {
        setupPushNotifications();
      },
      innards: "Connect to push service"
    }),

    button({
      onPush: () => {
        if (typeof window !== undefined) {
          window.location.reload();
        }
      },
      innards: "Update"
    }),

    button({
      onPush: () => APP().view = "alt-logins",
      innards: "Alt Logins"
    }),

    button("LogOut", {
      onPush: () => APP().api.logout(),
      innards: "Log out"
    }),
  ])
}