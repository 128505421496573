import "./app.scss";
import "../../styling/tailwind-ish.scss";


import { absLayout, stack } from "../../../../helium-star/src";
import { APP } from "../../state/app.state";
import { renderDevDash } from "../dev-dash";
import { renderLogIn } from "../login";
import { renderMainDash } from "../main-dash";
import { renderBackButton } from "../back-button";
import { DerivationManager } from "helium-sdx";



export function renderApp() {
  APP();

  return absLayout("App", {
    ddx: (ref) => {
      let { color1, color2 } = (APP().me && APP().me.avatar) || {};
      if (!color1 && !color2) {
        color1 = "#39dfb7";
        color2 = "#8e49b1";
      }
      if (!color1 || !color2) {
        ref.style.background = color1 || color2
      } else {
        ref.style.background = `linear-gradient(155deg, ${color1} 5%, #6198b4 50%, ${color2} 90%)`
      }

      // ref.style.background = `linear-gradient(to bottom right, ${"#39dfb7"}, ${"#8e49b1"})`
      // ref.style.background = `linear-gradient(155deg, ${"#39dfb7"} 5%, #6198b4 50%, ${"#8e49b1"} 90%)`
    }
  },[
    () => APP().overlay && absLayout("OverlayContainer", [
      stack("p-10 bg-darken-700 width-full height-full", {
        onTargetedPush: () => APP().overlay = undefined,
        innards: APP().overlay
      })
    ]),
    stack("MainContent", (_, { scope }) => {
      if (!APP().me) {
        return renderLogIn();
      }
      return renderMainDash();
    }),
    // () => {
    //   if (!APP().me) { return; }
    //   if (["invites", "contacts", "settings", "log-in" ].includes(APP().view)) {
    //     return;
    //   }
    //   return renderBackButton();
    // },
  ])
}