import { SelectNullable, SelectRequired } from "../misc/utility-types";

export const NotificationIdPrefix = "noti";

export const NotificationTypeList = [
  "new-invite",
  "new-contact-request",
  "new-invite-response",
  "gather-updated",
  "contact-accepted",
] as const;
export type NotificationType = typeof NotificationTypeList[number];

export const ExampleNotification = {
  id: NotificationIdPrefix + ":1",
  createdAt: new Date(),
  seenAt: null as Date | null,
  archivedAt: null as Date | null,
  type: "new-invite" as NotificationType,
  userId: "usr:seph",
  contextId: "nvit:2",
}


export type NotificationModel = SelectNullable<typeof ExampleNotification, "seenAt" | "archivedAt" | "contextId">;
export type NotificationArgs = SelectRequired<NotificationModel, "type" | "userId">;