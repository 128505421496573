import { SourcifiedObject } from "helium-sdx";
import { Renderers } from "../setup";


export function syncToStorage<T extends object>(id: string, src: SourcifiedObject<T>) {
  const { HTML, NS } = Renderers;

  if (HTML) {
    HTML.syncWithLocalStorage(id, src);
  } else if (NS) {
    NS.syncToStorage(id, src);
  }
}