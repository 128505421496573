import { Entity, EntityManager, SourceRepo } from "helium-source-repo";
import { ExamplePairing, PairingArgs, PairingModel} from "../../../middleware/models/Pairing.model";
import { commonPathSetups } from "./ents-util";
import { APP } from "../client";


export class PairingManager<
  ENT extends PairingEntity = PairingEntity
> extends EntityManager<PairingEntity> {
  initiateDefaultArgs(): void {
    this.defaultArgs = {
      repo: new PairingRepo(),
      createEntity: (args) => new PairingEntity(args),
    };
  }
}



export class PairingEntity 
  extends Entity<PairingModel, "id", PairingArgs> 
  implements Record<keyof PairingModel, any> 
{
  public get id() { return this.getId(); }
  public get createdAt() { return this.getProp("createdAt"); }
  public get fromId() { return this.getProp("fromId"); }
  public get toId() { return this.getProp("toId"); }

  public get fromUser() { return APP().ents.user.get(this.fromId); } 
  public get toInvite() { return APP().ents.invite.get(this.toId); } 
}



export class PairingRepo
extends SourceRepo<PairingModel, "id", PairingArgs> {
  constructor() {
    super({
      idPropName: "id",
      repoId: "pairing",
      ...commonPathSetups("pairing"),
      allFieldsNames: Object.keys(ExamplePairing) as any
    }, {
      getJoinFilter: (key, item) => {
        switch(key) {
          case "FromUser": return { repo: APP().ents.user.repo, where: { id: item.fromId }}
          case "ToInvite": return { repo: APP().ents.invite.repo, where: { id: item.toId }}
        }
        throw new Error("unknown name");
      },
      // whereRules: {
      //   endedAt: null
      // }
    })
  }
}