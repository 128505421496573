
import { DeriverScope, DeriveFn, aLog, AnchorNode, AnchorStatus, DeriverScopeProps, DdxBatchingTypes } from "helium-sdx";
import { BaseNode } from "../el-tool/el-tool.io";
import { changeFreezeState, setupFreezeListeners } from "../helium";


export class DomAnchor extends AnchorNode {
	constructor(protected domNode: BaseNode) {
		super();

		this.details = [`(Right Click > Reveal in Elements Panel).  Issue occurred in render preceding: `, domNode];

  	const helium = setupFreezeListeners(domNode)._helium;
    

		(helium.unfreeze = helium.unfreeze || []).push(() => {
			this.setStatus(AnchorStatus.NORMAL);
		});

		(helium.freeze = helium.freeze || []).push(() => {
			this.setStatus(AnchorStatus.FROZEN);
		});
	}

	public updateStatus() {
		const onPage = this.domNode.isLoaded;
    changeFreezeState(this.domNode as any, !onPage);
	}
}


export function deriveDomAnchored(
  node: BaseNode,
  fnOrProps: DeriveFn | Omit<DeriverScopeProps, "anchor">,
  batching?: DdxBatchingTypes
): DeriverScope {
  let props: DeriverScopeProps;
  if (typeof fnOrProps === "function") {
    props = {
      fn: fnOrProps
    };
  } else {
    props = fnOrProps;
  }
  props.batching = props.batching || batching || "singleFrame";

  const anchor = new DomAnchor(node);
  const fn: DeriveFn = (ddxState) => {
    anchor.updateStatus();
    props.fn(ddxState);
  };

  const scope = new DeriverScope({
    ...props,
    fn,
    anchor,
  });
  scope.runDdx();

  return scope;
}










