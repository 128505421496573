import { NotificationEntity } from "../../../client/src/ents";
import { list, stack, StackLayoutProps } from "../../../helium-star/src";
import { Innards } from "../../../helium-star/src/el-tool/el-tool.io";
import { APP } from "../state/app.state";

APP.registerViewSetup({
  title: "Notifications",
  matchCheck: "notifications",
  rootwardsStyle: "close",
  onRootwards: "back",
})

export function renderNotificationList() {
  return stack("NotificationList", () => {
    const { notifications } = APP().me;
    console.log(notifications);
    if (notifications.loading) {
      return "Loading..."
    }
    const items = notifications.getFresh();
    if (!items.length) {
      return "No notifications here";
    }

    return list(items.reverse().map((notif) => {
      switch (notif.type) {
        case "new-invite": return renderNewInviteNotif(notif);
        case "new-contact-request": return renderNewContactRequest(notif);
        case "gather-updated": return renderGatherUpdate(notif);
        case "contact-accepted": return renderContactAccepted(notif);
        case "new-invite-response": return renderNewInviteResponses(notif);
      }
      return stack([
        notif.id,
        notif.type,
        notif.contextId
      ])
    }))
  })
}

function renderNotifItem(notif: NotificationEntity, props: StackLayoutProps) {
  return stack("li-card", {
    ...props,
    ddxClass: () => !notif.seenAt ? "li-highlighted" : "opacity-50",
    onPush: () => {
      props.onPush && props.onPush();
      notif.update({ seenAt: Date.now() as any });
    },
  })
}

function renderNewInviteNotif(notif: NotificationEntity) {
  return renderNotifItem(notif, {
    onPush: () => APP().view = `gathering/view/${notif.context.asInvite.gatherId}`,
    innards: () => {
      const invite = notif.context.asInvite;
      const gather = invite && invite.gather;
      const inviteBackers = invite && invite.backers.items;
      if (!inviteBackers) { return; }
      return `${inviteBackers.map((user) => user.displayName)} invited you to the gathering "${gather.name}"`
    }
  })
}


function renderNewContactRequest(notif: NotificationEntity) {
  return renderNotifItem(notif, {
    onPush: async () => {
      const role = notif.context.asRole;
      // await role.updatePromise;
      APP().view = `contact/view/${role.ownerId}`
    },
    innards: () => {
      const role = notif.context.asRole;
      if (!role) { return; }
      return `${role.owner?.displayName} sent you a contact request`
    }
  })
}

function renderGatherUpdate(notif: NotificationEntity) {
  return renderNotifItem(notif, {
    onPush: async () => {
      APP().view = `gathering/view/${notif.contextId}`;
    },
    innards: () => {
      const context = notif.context.asGather;
      if (!context) { return; }
      return `The gathering "${context.name}" has details that've been updated`;
    }
  })
}

function renderContactAccepted(notif: NotificationEntity) {
  return renderNotifItem(notif, {
    onPush: async () => {
      const role = notif.context.asRole;
      APP().view = `contact/view/${role.targetId}`
    },
    innards: () => {
      const context = notif.context.asRole;
      if (!context) { return; }
      return `${context.target?.displayName} has accepted your contact request`
    }
  })
}


function renderNewInviteResponses(notif: NotificationEntity) {
  return renderNotifItem(notif, {
    onPush: () => APP().view = `gathering/view/${notif.contextId}`,
    innards: () => {
      const context = notif.context.asGather;
      if (!context) { return; }
      return `The gathering "${context.name}" has new responses for your invites`
    }
  })
}