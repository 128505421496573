import { SelectNullable, SelectRequired } from "../misc/utility-types";

export const PairingIdPrefix = "pair";

export const ExamplePairing = {
  id: PairingIdPrefix + ":1",
  createdAt: new Date(),
  fromId: "usr:112-123", 
  toId: "nvit:1", 
}


export type PairingModel = typeof ExamplePairing;
export type PairingArgs = SelectRequired<PairingModel, "fromId" | "toId">;