
import { labelH2, stack, textField, button, label } from "../../../helium-star/src";
import { APP } from "../state/app.state";
import { renderSaveEditsButton } from "./save-edits-button";


export function renderLogIn(mode: "login" | "add-alt" = "login") {
  let emailInput;
  return stack("LogIn text-center", [
    "",
    "",
    label("text-6xl", "⛵️"),
    label("text-3xl", "Togather"),
    "",
    "",
    stack("card", () => {
      const first = textField({
        placeholder: "First Name"
      });
      const last = textField({
        placeholder: "Last Name"
      });
      const password = textField({
        secure: true, 
        placeholder: "Password"
      });

      return [
        first,
        last,
        password,
        renderSaveEditsButton({
          text: mode === "login" ? "Login" : "Add Alt Account",
          editor: {
            get hasEdits() { return !!(
              first.value.get() && last.value.get() && password.value.get()
            )},
            save: async () => APP().api.login({
              firstName: first.value.get(),
              lastName: last.value.get(),
              password: password.value.get(),
            }),
          },
          then: () => {
            if (mode === "login") {
              APP().view = "gatherings";
            } else {
              APP().overlay = null;
            }
          }
        })
      ]
    }),
  ])
}