import { Loading } from "../../../client/src";
import { GatherEntity, UserEntity } from "../../../client/src/ents";
import { list, stack, SourceMap, label } from "../../../helium-star/src";
import { APP } from "../state/app.state";
import { renderEmojiAvatar } from "./emoji-avatar";
import { renderSaveEditsButton } from "./save-edits-button";


export function renderInvitesEditor({ gather }: {
  gather: GatherEntity
}) {
  const selection = new SourceMap<UserEntity, void>();
  return stack("InvitesEditor", [
    () => {
      let inviteUs = APP().me.suggestedInvitesForGathering(gather);
      if (inviteUs === Loading) { return; }
      if (!inviteUs.length) { return stack("card", "No Contacts to Invite"); }

      return list("card p-0", inviteUs.map(({user}) => 
        stack("User", {
          onPush: () => selection.has(user) ? selection.delete(user) : selection.set(user),
          ddxClass: () => selection.has(user) ? "li-highlighted" : "opacity-75",
          orientation: "horizontal",
          innards: [
            renderEmojiAvatar(() => ({
              size: "sm",
              ...user.avatar.toObject()
            })),
            label("px-10", user.displayName)
          ]
        })
      ));
    },
    "",
    renderSaveEditsButton({
      editor: {
        get hasEdits() { return !selection.isEmpty(); },
        async save() {
          const users = selection.keys();
          await Promise.all(users.map((user) => APP().repos.invite.assertWithBacker({
            gatherId: gather.id,
            toId: user.id,
            fromId: APP().me.id
          })))
          // const invites = users.map((user) => APP().ents.role.new({
          //   ownerId: APP().me.id,
          //   targetId: user.id,
          //   contextId: gather.id,
          //   status: "pending"
          // }));

          // roles.forEach((role) => role.save());
          return true;
        }
      },
      text: "Invite Em!",
      then: () => APP().overlay = undefined,
    })
  ])
}