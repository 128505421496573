import { SelectNullable, SelectRequired } from "../misc/utility-types"

export const GatherIdPrefix = "gthr";


export const ExampleGather = { 
  id: GatherIdPrefix + ":_1",
  createdAt: new Date(),
  createdBy: "Misty", 
  archivedAt: null as null | Date,
  name: "Lake Walk", 
  info: "Walk??", 
  extendable: true 
}


export type GatherModel = SelectNullable<typeof ExampleGather, "info" | "extendable" | "archivedAt">;

export type GatherArgs = SelectRequired<GatherModel, "name" | "createdBy">