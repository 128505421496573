import { button } from "../../../helium-star/src";
import { APP } from "../state/app.state";
import { Entity } from "../../../client/src"
import { DerivationManager } from "helium-sdx";


export function renderSaveEditsButton(args: {       
  // groupId: string;
  editor: {
    hasEdits: boolean;
    save(): Promise<boolean>;
  },
  extraCheck?: () => boolean;
  text?: string;
  then?: () => any;
}) {
  return button("SaveEdits", {
    ddxClass: () => {
      const { hasEdits } = args.editor;
      const extraCheck = args.extraCheck ? args.extraCheck() : true;
      // DerivationManager.debugDeriver();
      // console.log(DerivationManager._getCurrentDeriver()); 
      // console.log(args.editor.hasEdits, args.editor);       
      return hasEdits && extraCheck ? "btn-highlighted" : "btn-disabled"
    },
    onPush: async () => {
      await args.editor.save();
      args.then && args.then();
    },
    innards: args.text || "Save Edits",
  })
}