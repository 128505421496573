import { jit } from "helium-sdx";
import { Entity, EntityManager, SourceRepo } from "helium-source-repo";
// import { debugDeriver } from "../../../helium-star/src";
import { ExampleGather, GatherArgs, GatherModel} from "../../../middleware/models/gather.model";
import { APP } from "../client";
import { jitDdxSource } from "../utils/jit-ddx-source";
import { Loading } from "../utils/loading";
import { commonPathSetups } from "./ents-util";
import { UserEntity } from "./User.ents";


export class GatherManager<
  ENT extends GatherEntity = GatherEntity
> extends EntityManager<ENT> {
  initiateDefaultArgs(): void {
    this.defaultArgs = {
      repo: new SourceRepo({
        idPropName: "id" as never,
        repoId: "gather",
        ...commonPathSetups("gather"),
        allFieldsNames: Object.keys(ExampleGather),
      }),
      createEntity: (args) => new GatherEntity(args),
    } as any;
  }
}


export class GatherEntity 
extends Entity<GatherModel, "id", GatherArgs> 
implements Partial<GatherModel> {
  public get id() { return this.getId(); }
  public get createdAt() { return this.getProp("createdAt"); }
  public get archivedAt() { return this.getProp("archivedAt"); }
  public get createdBy() { return this.getProp("createdBy"); }
  public get name() { return this.getProp("name"); }
  public get info() { return this.getProp("info"); }
  public get extendable() { return this.getProp("extendable"); }


  public get invitees() {
    return jitDdxSource(this, "invitees", () => {  

      const { user, role, invite, pairing, runQuery } = APP().join;
      console.log("invitees");
      const resp = runQuery({
        "Invites": invite({ where: { gatherId: this.id }}, {
          To: user({singular: true}),
          Backers: pairing(),
        }),
      });

      return !resp ? [] : resp.Invites.map(({item, To, Backers}) => ({
        invite: item,
        user: To?.item,
        backers: Backers.map(({item}) => item)
      }));
    })
  }

  public get goingList() {
    return jitDdxSource(this, "goingList", () =>
      this.invitees.filter(({invite}) => invite.response === "going")
    )
  }

  public get interestedList() {
    return jitDdxSource(this, "intslist", () =>
      this.invitees.filter(({invite}) => invite.response === "interested")
    )
  }

  public invitesFor(user: UserEntity) {
    return jitDdxSource(this, `invitesFor-${user.id}`, () => 
      this.invitees.filter((invitee) => invitee.user && invitee.user.id === user.id).map(({invite}) => invite)
    )
  }

  public get myNotifs() {
    return jit(this, "myNotifs", () => {
      if (!APP().me) { return null; }
      // const notifHandler = ;

      // const handler = queryHandler({
      //   invites: invite({ where: { toId: APP().me!.id, gatherId: this.id }}, {

      //   })
      // })
      return APP().me!.unseenNotifys.createSubHandler({
        convertResponse: (items) => !items ? [] : items.filter((it) => it.contextId === this.id)
      })
    });
  }

  public get myInvite() {
    return jit(this, "myInvite", () => {
      if (!APP().me) { return null; }
      return APP().ents.invite.find.one.handler({ where: { gatherId: this.id, toId: APP().me!.id }});
    });
  }
}