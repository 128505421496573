import { GatherModel } from "../gather.model";
import { InviteModel } from "../Invite.model";
import { RoleModel } from "../role.model";
import { UserModel } from "../user.model";
import { PairingModel } from "../pairing.model";
import { NotificationModel } from "../notification.model";


export const MIDDLEWARE_TEST_DATA = {
  users: [] as UserModel[],
  roles: [] as RoleModel[],
  gathers: [] as GatherModel[],
  invites: [] as InviteModel[],
  pairings: [] as PairingModel[],
  notifys: [] as NotificationModel[],
}

MIDDLEWARE_TEST_DATA.users = [
  { id: "u:seph",
    createdAt: new Date(),
    updatedAt: new Date(),
    name: { first: "Seph", last: "Reed", alias: "Thumbz" },
    avatar: { emoji: "🖖🏼", color1: "teal", color2: "turquoise", },
    passwordHash: "pw",
  },
  { id: "u:mist",
    createdAt: new Date(),
    updatedAt: new Date(),
    name: { first: "Misty", last: "Nickle", alias: "Bebe" },
    avatar: { emoji: "👻", color1: "teal", color2: "purple" },
    passwordHash: "pw",
  },
  { id: "u:stev",
    createdAt: new Date(),
    updatedAt: new Date(),
    name: { first: "Steve", last: "Ward" },
    passwordHash: "pw",
  },
  { id: "u:aeri",
    createdAt: new Date(),
    updatedAt: new Date(),
    name: { first: "Aerica", last: "Raven" },
    passwordHash: "pw",
  },
  { id: "u:theo",
    createdAt: new Date(),
    updatedAt: new Date(),
    name: { first: "Theo", last: "Lemeowski" },
    avatar: { emoji: "😻", color1: "brown", color2: "grey" },
    passwordHash: "pw",
  },
  { id: "u:alex",
    createdAt: new Date(),
    updatedAt: new Date(),
    name: { first: "Alex", last: "L" },
    passwordHash: "pw",
  },
]



MIDDLEWARE_TEST_DATA.roles = [
  { id: "role:1",
    createdAt: new Date(),
    ownerId: "u:stev", 
    targetId: "u:seph", 
    type: "contact",
    status: "pending",
    details: {
      msg: {
        owner: "lalal",
      }
    }
  },
  { id: "role:2",
    createdAt: new Date(),
    ownerId: "u:seph", 
    targetId: "u:mist", 
    type: "contact",
    status: "accepted",
    details: {
      msg: {
        target: "It's Bebe!"
      }
    }
  },
  { id: "role:3",
    createdAt: new Date(),
    ownerId: "u:seph", 
    targetId: "u:theo", 
    type: "contact",
    status: "accepted",
    details: {
      msg: {
        target: "It's Theo!"
      }
    }
  },
]



MIDDLEWARE_TEST_DATA.gathers = [
  { id: "g:lake",
    createdAt: new Date(),
    createdBy: "u:mist", 
    name: "Lake Walk", 
    info: "Walk??", 
    extendable: true 
  },
  { id: "g:eldora",
    createdAt: new Date(),
    createdBy: "u:seph", 
    name: "Go to Eldora", 
    info: "Let's get our ski on.  Maybe monday?  Who knows.", 
    extendable: true 
  },
  { id: "g:mic-atrium",
    createdAt: new Date(),
    createdBy: "u:mist", 
    name: "Open Mic at Atrium", 
    info: "Gotta be good enough, but seems fun.", 
    extendable: true 
  },
]


MIDDLEWARE_TEST_DATA.invites = [
  { id: "nvit:3",
    createdAt: new Date(),
    toId: "u:seph",
    gatherId: "g:lake", 
    response: null,
    starred: false,
  },
  { id: "nvit:4",
    createdAt: new Date(),
    toId: "u:mist",
    gatherId: "g:eldora", 
    response: "interested",
    starred: false,
  },
  { id: "nvit:5",
    createdAt: new Date(),
    toId: "u:seph",
    gatherId: "g:mic-atrium", 
    response: "interested",
    starred: false,
  },
]

MIDDLEWARE_TEST_DATA.pairings = [
  { id: "pair:1",
    createdAt: new Date(),
    fromId: "u:mist", 
    toId: "nvit:3",
  },
  { id: "pair:2",
    createdAt: new Date(),
    fromId: "u:seph", 
    toId: "nvit:4",
  },
  { id: "pair:3",
    createdAt: new Date(),
    fromId: "u:mist", 
    toId: "nvit:5",
  },
]


MIDDLEWARE_TEST_DATA.notifys = [
  { id: "noti:1",
    createdAt: new Date(),
    contextId: "nvit:3",
    userId: "u:seph",
    type: "new-invite",
  },
  { id: "noti:3",
    createdAt: new Date(),
    contextId: "nvit:4",
    userId: "u:mist",
    type: "new-invite",
  },
  { id: "noti:2",
    createdAt: new Date(),
    contextId: "role:1",
    userId: "u:seph",
    type: "new-contact-request",
  },
]