import { MIDDLEWARE_TEST_DATA } from "../models/__tests/test-data";
import { apiData } from "./apiDataType";



export const UserEntryAPI = {
  create: apiData({
    path: "/user/create",
    payloadEg: {
      joinToken: "rol:1:1::123abc",
      user: {
        name: {
          first: "Seph",
          last: "Reed",
          alias: "Thumbz" as string | undefined,
        },
        cleartextPassword: "pass123"
      }
    },
    responseEg: {
      user: MIDDLEWARE_TEST_DATA.users[0],
      inviteRole: MIDDLEWARE_TEST_DATA.roles[0],
      contactRole: MIDDLEWARE_TEST_DATA.roles[1],
      userToken: "abc123"
    },
  }),


  login: apiData({
    path: "/login",
    payloadEg: {
      firstName: "seph",
      lastName: "reed",
      password: "pw"
    },
    responseEg: {
      user: MIDDLEWARE_TEST_DATA.users[0],
      userToken: "abc123"
    },
  }),  
}