

// {
//   path: "/roles/accept-contact",
//   methods: { post: {
//     requestBodyJson: {
//       id: "string"
//     }
//   }

export type ApiData = {
  path: string,
  payloadEg?: any,
  responseEg?: any,
}

export function apiData<T extends ApiData>(out: T): T {
  return out;
}