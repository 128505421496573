import { apiData } from "./apiDataType";
import { MIDDLEWARE_TEST_DATA } from "../models/__tests/test-data";



export const RoleAPI = {
  addContact: {
    accept: apiData({
      path: "/roles/add-contact/accept",
      payloadEg: {
        id: "role:1"
      },
      responseEg: MIDDLEWARE_TEST_DATA.roles.slice(0, 2),
    }),
    request: apiData({
      path: "/roles/add-contact/request",
      payloadEg: {
        ownerId: "usr:1",
        targetId: "usr:2",
      },
      responseEg: MIDDLEWARE_TEST_DATA.roles.slice(0, 2),
    })
  },
}