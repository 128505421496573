

import { View } from "@nativescript/core/ui/core/view";
import { ViewBase } from "@nativescript/core/ui/core/view-base";
import { GestureTypes } from "@nativescript/core/ui/gestures";
import { Label } from "@nativescript/core/ui/label";
import { LayoutBase } from "@nativescript/core/ui/layouts/layout-base";
import { deriveDomAnchored } from "./derivations/dom-anchored-deriver";
import { RerenderReplacer } from "./derivations/rerender-replacer";
import { BaseNode, heliumHaze, IHeliumHazed, Innards, isHeliumHazed, isProps, IViewProps, LabelInnards } from "./el-tool/el-tool.io";


export { View };

export const _HELIUM_NS_SETTINGS = {
	webLikeGridNumbers: false
}



/**************************
*. BASIC HELPER FUNCTIONS
***************************/



export function haveClass(view: View, className: string, addNotRemove: boolean) {
	if (view === undefined) { const err = new Error("Undefined view"); console.error(err.stack); throw err; }
	let classes = view.className.split(/ +/g);
	if (addNotRemove) {
		if (classes.indexOf(className) === -1) {
			classes.push(className);
		}
	} else {
		classes = classes.filter((it) => it !== className);
	}
	view.className = classes.join(" ");
}



export interface ILableProps extends IViewProps {
	textWrap?: boolean;
}

export function label(
	classOrProps?: string | ILableProps,
	propsOrInnards?: ILableProps | LabelInnards,
	innardsOrNot?: LabelInnards,
) {
	const out = new Label();

	const input = standardizeInput<ILableProps, LabelInnards>(classOrProps, propsOrInnards, innardsOrNot);
	let { props, innards } = input;
	if (props) {
		applyProps(out, props);
		out.textWrap = props.textWrap !== undefined ? props.textWrap : true;
	}
	out.text = (typeof innards === "number") ? String(innards) : (innards || "");
	return out;
}





export function applyProps(view: View, props: IViewProps) {
	if (props.class) {
		view.className = props.class;
		delete props.class;
	}

	if (props.pointerEvents) {
		view.isUserInteractionEnabled = props.pointerEvents !== "none";
		delete props.pointerEvents;
	}

	if (props.on) {
		// tslint:disable-next-line:forin
		for (const key in props.on) {
			const asEnum = parseInt(key);
			view.addEventListener(isNaN(asEnum) ? key : asEnum as any, props.on[key]);
		}
		delete props.on;
	}

	if (props.onPush) {
		view.addEventListener(GestureTypes.tap as any, props.onPush);
		delete props.onPush;
	}

	if (props.bgUrl) {
		view.backgroundImage = `url("${props.bgUrl}")`;
		delete props.bgUrl;
	}

	const ref = props.ref;
	if (props.ref) {
		delete props.ref;
	}

	if (props.ddxClass) {
		let lastClassName: string;
		deriveDomAnchored(view, () => {
			const newClassName = " " + (props.ddxClass!() || "");
			if (lastClassName === newClassName) { return; }
			if (lastClassName) {
				view.className = view.className.replace(lastClassName, "");
			}
			view.className += newClassName;
			lastClassName = newClassName;
		})
	}


	// tslint:disable-next-line:forin
	for (const key in props) {
		(view as any)[key] = (props as any)[key];
	}

	if (props.col) {
		view.col = props.col + (_HELIUM_NS_SETTINGS.webLikeGridNumbers ? 1 : 0);
	}

	if (props.row) {
		view.row = props.row + (_HELIUM_NS_SETTINGS.webLikeGridNumbers ? 1 : 0);
	}

	if (ref) { ref(view); }
}





export function remove(view: View) {
	if (view && view.parent && view.parent instanceof LayoutBase) {
		view.parent.removeChild(view);
		freeze(view);
	}
}

export function children(view: ViewBase) {
	const out: View[] = [];
	view.eachChild(child => { 
		if (child instanceof View) {
			out.push(child as  View);
			return true;
		} 
		throw new Error("Got a view base");
	});
	return out;
}

export function removeChildren(view: View) {
	if (view instanceof LayoutBase) {
		view.eachChildView((child) => {
			freeze(child);
			return true;
		});
		view.removeChildren();
	}
}

export function unfreeze(root: View) {
	changeFreezeState(root, false);
}

export function freeze(root: View) {
	changeFreezeState(root, true);
}

export function changeFreezeState(target: BaseNode, toFrozen: boolean) {
	propigate(target, (view) => {
		if(isHeliumHazed(view) && toFrozen !== view._helium.frozen) {
			const fns = toFrozen ? view._helium.freeze : view._helium.unfreeze;
			// console.log("Freezing ", view);
			view._helium.frozen = toFrozen;
			fns!.forEach(fn => fn());
		}
	});
}

export function setupFreezeListeners<T extends View>(modMe: T) {
	const helium = heliumHaze(modMe)._helium;
	if (helium.freezeListeners) {
		return modMe as IHeliumHazed<T>;
	}
	helium.freezeListeners = {
		unfreeze: () => unfreeze(modMe),
		freeze: () => freeze(modMe),
	};
	modMe.on("loaded", helium.freezeListeners.unfreeze);
	modMe.on("unloaded", helium.freezeListeners.freeze);
	return modMe as IHeliumHazed<T>;
}



function propigate(root: View, cb: (view: View) => any) {
	cb(root);
	root.eachChildView((view) => {
		cb(view);
		return true;
	});
}

export function setInnards(root: LayoutBase, innards: Innards) {
	removeChildren(root);
	append(root, innards);
}



// Append stuff to an element.
export function append(
	root: LayoutBase,
	innards: Innards,
) {
	convertInnardsToNodes(innards).forEach((view) => {
		console.log(typeof view);
		remove(view);
		root.addChild(view);
		unfreeze(view);
	});
}

export function convertInnardsToNodes(innards: Innards): BaseNode[] {
	if (typeof innards === "function") {
		innards = (new RerenderReplacer(innards)).getRender();
	}
	if (innards === undefined || innards === null || innards === false) { return []; }
	if (Array.isArray(innards)) {
		innards = innards.map((child) => convertInnardsToNodes(child)).flat(42).filter((it) => !!it) as any as BaseNode[];
	}
	if (typeof innards === "number") { innards = innards + ""; }
	if (typeof innards === "string") {
		innards = label("", innards);
	}
	innards = Array.isArray(innards) ? innards : [innards];
	return innards as any;
	// return childList.map(_convertChildToView).flat(42);
}



export interface IPropsAndInnards<
	PROPS extends IViewProps = IViewProps,
	INN = Innards
> {
	props: PROPS;
	innards?: INN;
}

export function standardizeInput<
	PROPS extends IViewProps = IViewProps,
	INN = Innards
>(
	classNameOrPropsOrInnards?: string | PROPS | INN,
	propsOrInnards?: PROPS | INN,
	innards?: INN,
	innardsRazor?: (checkMe: any) => checkMe is INN,
): IPropsAndInnards<PROPS, INN> {
	let props: PROPS | undefined;
	let className: string | undefined;
	if (innards) {
		className = classNameOrPropsOrInnards as any;
		props = propsOrInnards as any;

	} else {
		innardsRazor = (innardsRazor || ((checkMe: any) => !isProps(checkMe))) as any;

		if (typeof classNameOrPropsOrInnards === "string") {
			className = classNameOrPropsOrInnards as any;
		} else if (innardsRazor!(classNameOrPropsOrInnards)) {
			innards = classNameOrPropsOrInnards;
		} else if (isProps(classNameOrPropsOrInnards)) {
			props = classNameOrPropsOrInnards;
		} else {
			throw new Error("Unknown argument type");
		}
		
		if (propsOrInnards) {
			if (innardsRazor!(propsOrInnards)) {
				innards = propsOrInnards;
			} else {
				props = propsOrInnards;
			}
		}
	}
	
	props = (props || {}) as any;
	if (className) {
		if (props!.class) {
			props!.class += " " + className;
		} else {
			props!.class = className;
		}
	}

	return {props: props!, innards};
}

