import { deriveAnchorless, noDeps, Source, Sourcify } from "helium-sdx";
import { RepoDataSource } from "helium-source-repo";
import { APP } from "../../../ui/shared/state/app.state";
import { UserEntity } from "../ents/User.ents";
import { ClientENV } from "../ENV";
import { UserEntryAPI } from "../../../middleware/endpoints/user-entry.apiDatas";

export type LoginInfo = { userId: string; token: string };

export class TogatherApi {
	protected static DEV_API_PATH = "https://dev-api.togather.earth";

	public loginState = Sourcify({
		current: null as null | LoginInfo,
		alternates: [] as Array<LoginInfo>
		// userId: null as string | null,
		// token: null as string | null,
	});

	public hasToken() { return !!this.loginState.current?.token; }

	public static get hostMode() {
		return location.href.includes("boulder.earth") ? "live" : "local";
	}

	public get hostMode() { return TogatherApi.hostMode; }

	public static get host() {
		if (typeof window !== "undefined") {
			if (window.location.host.includes("togather")) {
				return TogatherApi.DEV_API_PATH;
			}
		}
		return ClientENV.get().localApiPath;
		// if (typeof location === "undefined") {
		// 	console.warn("Using dev server");
		// 	return "http://localhost:3132";
		// }
		// return this.hostMode === "live" ? "https://dev-api.boulder.earth" : "http://localhost:3132";
	}

	// public massTagUseJoinUpdate(body: MassTagUseJoinUpdate) {
	// 	return this.post(this.paths.tagUse.massJoinUpdate, body);
	// }

	public url(suffix: string) {
		if (suffix.startsWith("http") === false) {
			suffix = TogatherApi.host + suffix;
		}
		return suffix;
	}

	public async get<T extends object | string = object>(url: string) {
		return this.fetch<T>("GET", url);
	}

	public async post<T extends object | string = object>(url: string, body: any) {
		return this.fetch<T>("POST", url, body);
	}

	public async fetch<T extends object | string = object>(method: "GET" | "POST", url: string, body?: any): Promise<T> {
		url = this.url(url);
		const resp = await fetch(url, {
			method,
			headers: body && {
				'Content-Type': 'application/json'
			},
			body: body && JSON.stringify(body),
		});

		const asText = await resp.text();

		if (resp.ok === false) {
			throw new Error(asText);
		}
		
		try {
			return JSON.parse(asText) as T;
		} catch (err) {
			return asText as any;
		}
	}


	public async getRandomPassword() {
		return this.get("/generate-pw") as Promise<string>;
	}

	public async validateJoinToken(joinToken: string) {
		const {user, role} = await this.post<any>("/validate-join-token", { joinToken });
		APP().repos.role.dataSource.setItem(role);
		APP().repos.user.dataSource.setItem(user);
		return {
			user: APP().ents.user.get(user.id),
			role: APP().ents.role.get(role.id),
		}
	}


	public async createUser(body: typeof UserEntryAPI.create.payloadEg) {
		const resp = await this.post<typeof UserEntryAPI["create"]["responseEg"]>(
			UserEntryAPI.create.path, 
			body
		);
		APP().repos.role.dataSource.setItems([resp.inviteRole, resp.contactRole]);
		APP().repos.user.dataSource.setItem(resp.user);

		this.loginState.upsert({
			current: {
				token: resp.userToken,
				userId: resp.user.id,
			}
		});

		return {
			user: APP().ents.user.get(resp.user.id),
			inviteRole: APP().ents.role.get(resp.inviteRole.id),
		}
	}

	public async login(body: typeof UserEntryAPI.login.payloadEg, ) {
		const resp = await this.post<typeof UserEntryAPI["login"]["responseEg"]>(
			UserEntryAPI.login.path,
			body
		);

		APP().repos.user.dataSource.setItem(resp.user);

		const current = this.loginState.current;
		current && this.loginState.alternates.push(current.toObjectNoDeps());
		this.loginState.upsert({
			current: {
				token: resp.userToken,
				userId: resp.user.id,
			}
		});
		// const user = APP().ents.user.get(resp.user.id);
		// APP().user.currentUser = user;
		return true;
	}	


	public logout() {
		this.loginState.upsert({
			current: null
		})
	}
}
