import { ElFunction, HeliumElement, IElProps, Innards, PrimitiveInnards, SingleInnard, SingleItemInnard, SinglePrimitiveInnard, StandardArgs } from "./el-tool.io";
import { standardizeInput } from "./el-tool";
import { DerivationManager, Source, SourceBase } from "helium-sdx";
import { Renderers } from "../setup";

import type { Label, StackLayout, TextField } from "@nativescript/core";
import type { SelectedIndexChangedEventData } from "tns-core-modules"


export function debugDeriver() {
  DerivationManager.debugDeriver();
  return DerivationManager._getCurrentDeriver();
}


function _simpleFactory<
  INN = Innards, 
  PROPS extends IElProps<HeliumElement, INN> = IElProps<HeliumElement, INN>
>(seed: {
  html: string;
  ns: string;
  specialProps?: (item: HeliumElement, props: PROPS) => any;
}) {
  return ((...args: StandardArgs) => {
    const { HTML, NS } = Renderers;
    const { props, innards } = standardizeInput<INN, PROPS>(...args as any);
    let out: HeliumElement;
    if (HTML) {
      out = el(seed.html as any, props as any, innards as any) as HTMLElement;
    } else if (NS) {
      // return seed.ns(props, innards as any);
      out = (NS as any)[seed.ns](props, innards);
    } else {
      throw new Error(`SET_TARGET("NS" | "HTML") must be called before using tool`);
    }
    if (seed.specialProps) {
      seed.specialProps(out, props);
    }
    return out;
  }) as ElFunction<HeliumElement, INN, PROPS>;
}


const el = ((name: string, props?: IElProps, innards?: Innards) => {
  const { HTML, NS } = Renderers;
  if (!HTML) {
    throw new Error(`Not in HTML`);
  }
  const out = HTML.el(name as any, props as any, innards as any) as HTMLElement;

  if (props) {
    if ("row" in props || "rowSpan" in props) {
      const row = (props.row || 1);
      const rowEnd = row + (props.rowSpan || 1);
      const gridRow = `${row} / ${rowEnd}`;
      out.style.setProperty("grid-row", gridRow);
    }
    if ("col" in props || "colSpan" in props) {
      const col = (props.col || 1);
      const colEnd = col + (props.colSpan || 1);
      const gridCol = `${col} / ${colEnd}`;
      out.style.setProperty("grid-column", gridCol);
    }
    if ("onTargetedPush" in props) {
      HTML.onPush(out, (ev) => {
        ev.target === out && props.onTargetedPush!();
        return ev as any;
      })
    }
  }
  
  return out;
})



export type StackLayoutProps = IElProps & {
  orientation?: "vertical" | "horizontal";
}

export const stack = _simpleFactory<Innards, StackLayoutProps>({
  html: "StackLayout",
  ns: "stack",
  specialProps: (item, props) => {
    const { HTML, NS } = Renderers;
    if (props.orientation) {
      if (HTML) {
        (item as HTMLElement).classList.add("stack-"+props.orientation)
      } else if (NS) {
        (item as StackLayout).orientation = props.orientation;
      }
    }
  }
})

export const absLayout = _simpleFactory({
  html: "AbsoluteLayout",
  ns: "absLayout"
})

export const wrap = _simpleFactory({
  html: "WrapLayout",
  ns: "wrap"
})

export const button = _simpleFactory<PrimitiveInnards>({
  html: "button",
  ns: "button"
});

export const scroll = _simpleFactory({
  html: "ScrollView",
  ns: "scroll"
})


export type LabelProps = IElProps<HeliumElement, PrimitiveInnards> & {
  header?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
}

export const label = ((...args: StandardArgs<LabelProps, PrimitiveInnards>) => {
  const { HTML, NS } = Renderers;
  const { props, innards } = standardizeInput<PrimitiveInnards, LabelProps>(...args as any);
  if (HTML) {
    const out = el("Label" as any, props as any) as HTMLElement;
    out.textContent = String(innards);
    props.header && out.classList.add(props.header);
    return out;
  } else if (NS) {
    // return seed.ns(props, innards as any);
    const out = (NS as any)["label"](props, innards) as Label;
    props.header && out.cssClasses.add(props.header);
    return out;
  }
}) as ElFunction<HeliumElement, PrimitiveInnards, LabelProps>;

function _headerFactory(size: LabelProps["header"]) {
  return ((...args: StandardArgs<IElProps<HeliumElement, PrimitiveInnards>, PrimitiveInnards>) => {
    const out = label(...args as any);
    out.className += (" " + size);
    return out;
  }) as ElFunction
}

export const labelH1 = _headerFactory("h1");
export const labelH2 = _headerFactory("h2");
export const labelH3 = _headerFactory("h3");
export const labelH4 = _headerFactory("h4");
export const labelH5 = _headerFactory("h5");
export const labelH6 = _headerFactory("h6");



export type DockInnards = {
  top?: SingleInnard,
  left?: SingleInnard,
  right?: SingleInnard,
  bottom?: SingleInnard,
  fill?: SingleInnard,
}
export function dock(...args: StandardArgs<IElProps<HeliumElement>, DockInnards>) {
  const { HTML, NS } = Renderers;
  const razor = (checkMe: any) => {
    if (checkMe && typeof checkMe === "object" && ["top", "left", "right", "bottom", "fill"].find((it) => it in checkMe)) {
      return true;  
    }
    return false;
  }
  const { props, innards } = standardizeInput(args[0] as any, args[1], args[2], razor as any);
  if (HTML) {
    const out: HTMLElement = el("DockLayout" as any, props);
    Object.entries(innards).sort((a) => a[0] === "fill" ? 1 : -1).forEach(([key, child]) => {
      const addMe = (child instanceof HTMLElement ? child : label("", child as PrimitiveInnards)) as HTMLElement;
      addMe.classList.add("dock-" + key);
      out.appendChild(addMe);
    })
    return out as any;
  } else if (NS) {
    return NS.dock(props, innards as any);
  }
}


export function checkbox(...args: StandardArgs<IElProps<HeliumElement, PrimitiveInnards>, PrimitiveInnards>) {
  const { HTML, NS } = Renderers;
  const { props, innards } = standardizeInput(...args);
  if (HTML) {
    const checkbox = el("input", props as any) as HTMLInputElement;
    checkbox.type = "checkbox";
    const out: HTMLElement = el("label", props as any, innards as any);
    out.appendChild(checkbox);
    return out as any;
  }
  console.error("NO CHECKBOX YET")
}




export type ChecklistInnards = Array<PrimitiveInnards>
// //  | {
// //   value: string;
// }>
export function checklist(...args: StandardArgs<IElProps<HeliumElement, ChecklistInnards>, ChecklistInnards>) {
  const { HTML, NS } = Renderers;
  const { props, innards } = standardizeInput<ChecklistInnards>(...args as any);
  const out = stack(props);
  (innards as ChecklistInnards).forEach((it) => {
    const addMe = stack("checklistItem", {
      onPush: () => addMe.checked = !addMe.checked,
      innards: it,
    }) as HeliumElement & { checked: boolean};

    if (HTML) {
      (out as HTMLElement).appendChild(addMe as any);
    } else {
      throw new Error("NO NS YET");
    }
  });
  return out;
}




export type SelectedIndexChangeEvent = Omit<SelectedIndexChangedEventData, "object"> & {
  object: HeliumElement;
}

export type SegmentedBarProps = IElProps<HeliumElement, PrimitiveInnards> & {
  selectedIndex?: number;
  selectedIndexChange: (ev: SelectedIndexChangeEvent) => any;
}

export function segmentedBar(
  ...args: StandardArgs<SegmentedBarProps, PrimitiveInnards>
): HeliumElement {
  const { HTML, NS } = Renderers;
  const { props, innards } = standardizeInput<PrimitiveInnards, SegmentedBarProps>(...args as any);
  if (HTML) {
    const out: HTMLElement = el("SegmentedBar" as any, props);
    const baseEvent: SelectedIndexChangeEvent = {
      newIndex: props.selectedIndex || 0,
      oldIndex: -1,
      eventName: "selectedIndexChange",
      object: out,
    }
    out.setAttribute("selectedIndex", String(props.selectedIndex || 0));
    const childList = Array.isArray(innards) ? innards : [innards];
    childList.forEach((child, index) => {
      const addMe = el("SegmentedBarItem" as "div");
      addMe.title = String(child);
      addMe.textContent = String(child);
      addMe.addEventListener("click", () => {
        if (index === baseEvent.newIndex) {
          return;
        }
        out.setAttribute("selectedIndex", String(index));
        baseEvent.oldIndex = baseEvent.newIndex;
        baseEvent.newIndex = index;
        props.selectedIndexChange({...baseEvent});
      })
      out.appendChild(addMe);
    })
    return out;
  } else if (NS) {
    return NS.segmentedBar(props as any, innards as any) as any;
  }
  throw "No Renderer";
}





export function list(
  ...args: StandardArgs<IElProps<HeliumElement, SingleItemInnard[]>, SingleItemInnard[]>
): HeliumElement {
  const { HTML, NS } = Renderers;
  const { props, innards } = standardizeInput<SingleItemInnard[]>(...args as any);
  if (HTML) {
    const children = innards?.map((it) => {
      if (it instanceof HTMLElement) {
        return it;
      }
      return stack([it]);
    });
    return el("ListView" as any, props as any, children as any);
  } else if (NS) {
    return NS.list(props as any, innards as any) as any;
  }
  throw "No Renderer";
}


/**
 * @tutorial https://v6.docs.nativescript.org/angular/ui/ng-components/layouts#grid-layout
 * @example { columns: "*,*,2*" }
 */
export type GridLayoutProps = IElProps<HeliumElement, Innards> & {
  columns: string;
  rows: string;
}

export function grid(...args: StandardArgs<GridLayoutProps, Innards>): HeliumElement {
  const { HTML, NS } = Renderers;
  const { props, innards } = standardizeInput<Innards, GridLayoutProps>(...args as any);
  if (HTML) {
    const out: HTMLElement = el("GridLayout" as any, props, innards);
    const toSizes = (str: string) => str.split(",").map((it) => {
      const match = it.match(new RegExp(/(\d*)\*/));
      if (!match) { return it; }
      return !match ? it : `${parseInt(it) || 1}fr`;
    }).join(" ")
    out.style.setProperty("grid-template-columns", toSizes(props.columns));
    out.style.setProperty("grid-template-rows", toSizes(props.rows));
    return out;
  } else if (NS) {
    return NS.grid(props as any, innards as any) as any;
  }
  throw "No Renderer";
}




// export type ActionBarProps = IElProps<HeliumElement, Innards> & {
//   title: string;
// }

// export type ActionBarInnards = {
//   left?: SingleItemInnard;
//   right?: SingleItemInnard
// }

// /**
//  * @tutorial https://v6.docs.nativescript.org/angular/ui/ng-components/action-bar
//  */
// export function actionBar(...args: StandardArgs<ActionBarProps, ActionBarInnards>) {
//   return NS.actionBar("test", { title: "TEST LA"});
// }



/**
 * @tutorial https://v7.docs.nativescript.org/ui/components/text-field
 */
export type TextFieldProps = IElProps<HeliumElement, TextFieldInnards> & Partial<Pick<
  TextField, 
  "text" | "keyboardType" | "returnKeyType" | "autocapitalizationType" | "secure"
>> & {
  placeholder?: string;
} 

export type TextFieldInnards = Source<SinglePrimitiveInnard> | SinglePrimitiveInnard 
| { get(): SingleItemInnard, set(val: SinglePrimitiveInnard): void }

export function textField(...args: StandardArgs<TextFieldProps, TextFieldInnards>): HeliumElement & { value: Source<string> } {
  const { HTML, NS } = Renderers;
  const { props, innards } = standardizeInput<TextFieldInnards, TextFieldProps>(...args as any);
  if (HTML) {
    const out: HTMLElement = el("TextField" as any, props as any);
    out.contentEditable = "true";
    const textSource = (innards && typeof innards === "object" && "get" in innards) ? innards : new Source(innards);
    HTML.deriveDomAnchored(out, () => {
      console.log("update value");
      const currentVal = out.textContent;
      const newVal = textSource.get();
      if (currentVal === newVal) { return; }
      out.textContent = String(textSource.get() || "")
    });
    props.placeholder && out.setAttribute("placeholder", props.placeholder);
    props.secure && out.classList.add("--password");
    out.addEventListener("input", () => textSource.set(out.textContent));
    (out as any).value = textSource;
    return out as any;
  } else if (NS) {
    return NS.textField(props as any) as any;
  }
  throw "No Renderer";
}




export type SwitchToggleProps = IElProps<HeliumElement, undefined> & {
  checked?: boolean | SourceBase<boolean>;
  offBackgroundColor?: string;
}

export function switchToggle(
  nameOrProps?: string | SwitchToggleProps,
  maybeProps?: SwitchToggleProps
): HeliumElement & { value: SourceBase<boolean> } {
  const { HTML, NS } = Renderers;
  const { props } = standardizeInput<undefined, SwitchToggleProps>(nameOrProps, maybeProps);
  if (HTML) {
    const out: HTMLElement = el("Switch" as any, props as any);
    const { checked } = props;
    const checkedSource = (checked && typeof checked === "object" && "get" in checked) ? checked : new Source(!!checked);
    HTML.deriveDomAnchored(out, () => {
      const currentVal = out.getAttribute("checked") === "true";
      const newVal = !!checkedSource.get();
      if (newVal === currentVal) { return; }
      out.setAttribute("checked", String(newVal));
    });
    out.addEventListener("click", () => {
      checkedSource.set(!checkedSource.get())
    });
    props.offBackgroundColor && (out.style.backgroundColor = props.offBackgroundColor);
    (out as any).value = checkedSource;
    return out as any;
  } else if (NS) {
    // return NS.textField(props as any) as any;
  }
  throw "No Renderer";
}