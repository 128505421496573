import { label } from "./el-tool/factories";

import type * as HWEB from "helium-ui";
import type * as HNS from "../../helium-ns/src";

export const Renderers: {
  HTML?: typeof HWEB;
  NS?: typeof HNS;
} = {};

export function SET_TARGET(target: "HTML" | "NS") {
  let { HTML, NS } = Renderers;
  if (HTML || NS) {
    throw new Error(`Target has already been set!`);
  }

  if (target === "HTML") {
    if (typeof window === "undefined") {
      throw new Error(`Can not have target of HTML in environment where "window" is not a global variable`);
    }
    HTML = Renderers.HTML = require("helium-ui");
    HTML!.createTextNodeHook.fn = (txt: string) => {
      const out = label() as HTMLElement;
      out.innerText = txt;
      return out as any;
    }
    require("./el-tool/native-matching.scss");

  } else if (target === "NS") {
    NS = Renderers.NS = require("../../helium-ns/src");
    NS!._HELIUM_NS_SETTINGS.webLikeGridNumbers = true;
    require("./el-tool/ns-polyfills.scss");
  }
}

// export function HTML() { return importedRenderers.HTML; }
// export function NS() { return importedRenderers.NS; }