import { GatherEntity } from "../../../client/src/ents";
import { InviteEntity } from "../../../client/src/ents/Invite.ents";
import { PairingEntity } from "../../../client/src/ents/Pairing.ents";
import { button, label, labelH2, list, segmentedBar, stack, wrap, grid, scroll } from "../../../helium-star/src";
import { APP } from "../state/app.state"
import { renderEmojiAvatar } from "./emoji-avatar";



APP.registerViewSetup({
  title: (view) => {
    if (view === "gatherings") { return "Gatherings"; }
    const subView = view.split("/")[1];
    switch (subView) {
      case "hosting": return "Gatherings You're Hosting";
      case "interested": return `Gatherings Marked "Interesting"`;
      case "going": return `Gatherings Marked "Going"`;
      case "archived": return `Archived Gatherings`;
      // case "archived": return `Archived Gatherings`;
    }
  },
  rootwardsStyle: "drawer",
  matchCheck: (view) => view.startsWith("gatherings"),
});


export function renderGatheringsView(subView?: string) {
  return scroll("GatheringListView", [
    button({
      onPush: () => APP().me.openGathers.refresh(),
      innards: "🔄"
    }),
    subView === "hosting" && button("CreateInvite btn-highlighted", {
      onPush: () => APP().view = "gathering/edit/new",
      innards: "Create a New Gathering"
    }),
    stack("messageOrElements", () => {
      switch (subView) {
        case "hosting": return renderHostingGatherList();
        case "going": return renderGoingGatherList();
        case "interested": return renderInterestedGatherList();
        case "archived": return renderArchivedGatherList();
        default: return renderOpenGatherList();
      }
    })
  ]);
}


function renderGatherList(args: {
  handler: { loading: boolean;  msSinceLastUpdate: number; refresh(): any; }
  getItems(): Array<{invite: InviteEntity | null; gather: GatherEntity; backers: PairingEntity[] | null}>
  emptyMessage: string;
}) {
  if (args.handler.loading) {
    return "Loading...";
  }
  if (args.handler.msSinceLastUpdate > 30 * 1000) {
    args.handler.refresh();
  }
  const items = args.getItems();
  if (!items.length) {
    return args.emptyMessage;
  }
  return items.map((it) => renderGatherListItem(it)) 
}


function renderOpenGatherList() {
  const handler = APP().me.openGathers;
  return renderGatherList({
    handler,
    getItems: () => handler.items,
    emptyMessage: "You currently have no gatherings to check out" 
  });
}

function renderHostingGatherList() {
  const pkg = APP().me.gathersHosting;
  return renderGatherList({
    handler: pkg,
    getItems: () => pkg.getFresh().map((gather) => ({invite: null, gather, backers: null})),
    emptyMessage: "You are not currently hosting any gatherings" 
  });
}

function renderGoingGatherList() {
  const pkg = APP().me.gathersGoing;
  return renderGatherList({
    handler: pkg,
    getItems: () => pkg.getFresh(),
    emptyMessage: `You do not currently have any gatherings you've responded with "going" to`
  });
}

function renderInterestedGatherList() {
  const pkg = APP().me.gathersInterested;
  console.log(pkg);
  return renderGatherList({
    handler: pkg,
    getItems: () => pkg.getFresh(),
    emptyMessage: `You do not currently have any gatherings you've responded with "interested" to`
  });
}

function renderArchivedGatherList() {
  const pkg = APP().me.gathersArchived;
  return renderGatherList({
    handler: pkg.handler,
    getItems: () => pkg.items,
    emptyMessage: `You do not currently have any gatherings that have been archived`
  });
}



function renderGatherListItem({gather, invite, backers}: { gather: GatherEntity, invite: InviteEntity | null, backers: PairingEntity[] | null }) {
  return grid("GatherListItem li-card", {
    columns: "auto,*,auto",
    rows: "auto,*",
    onPush: () => APP().view = `gathering/view/${gather.id}`,
    ddxClass: () => {
      // console.log("NOTIFS", gather.myNotifs?.getFresh(), gather.myNotifs?.getFresh().length, gather.name);
      if (gather.myNotifs?.getFresh().length || (invite && invite.myNotifys.getFresh().length)) {
        return "li-highlighted";
      }
    }
  },[
    () => {
      if (!invite) { return; }
      const fromUser = backers[0]?.fromUser;
      if (!fromUser) { return; }
      return renderEmojiAvatar(() => ({
        size: "2xl",
        ...fromUser.avatar
      }))
    },
    stack({
      col: 2,
      row: 1,
      rowSpan: 2,
      innards: () => [
        label("font-bold", gather.name),
        gather.info,
        stack("GoingList", () => gather.goingList.map(({user}) => user?.name?.first)),
        // invites && `invited by: ${invites.map((it) => it.fromId)}`
      ],
    }),
    () => {
      if (invite !== null) { return; }
      return stack({ row: 1, col: 3 }, [
        renderEmojiAvatar(() => ({
          size: "2xl",
          ...APP().me.avatar
        }))
      ])
    },
  ])
}

