import { Entity, EntityManager, SourceRepo } from "helium-source-repo";
import { ExampleInvite, InviteArgs, InviteModel} from "../../../middleware/models/Invite.model";
import { InviteAPI } from "../../../middleware/endpoints/Invite.apiDatas";
import { commonPathSetups } from "./ents-util";
import { UserEntity } from "./User.ents";
import { DerivationManager, jit, Sourcified } from "helium-sdx";
import { APP } from "../client";


export class InviteManager<
  ENT extends InviteEntity = InviteEntity
> extends EntityManager<InviteEntity> {
  initiateDefaultArgs(): void {
    this.defaultArgs = {
      repo: new InviteRepo(),
      createEntity: (args) => new InviteEntity(args),
    };
  }
}



export class InviteEntity 
  extends Entity<InviteModel, "id", InviteArgs> 
  implements Record<keyof InviteModel, any> 
{
  public get id() { return this.getId(); }
  public get createdAt() { return this.getProp("createdAt"); }
  public get response() { return this.getProp("response"); }
  public get archivedAt() { return this.getProp("archivedAt"); }
  // public get fromId() { return this.getProp("fromId"); }
  public get toId() { return this.getProp("toId"); }
  public get gatherId() { return this.getProp("gatherId"); }
  public get starred() { return this.getProp("starred"); }

  public get gather() { return APP().ents.gather.get(this.gatherId); } 
  public get backers() { 
    const { pairing, user, queryHandler } = APP().join;
    const handler = queryHandler({
      Pairings: pairing({ where: { toId: this.id }}, {
        FromUser: user({ singular: true })
      })
    });
    return {
      handler,
      get items() { return handler.value?.Pairings.map(({FromUser}) => FromUser?.item)}
    }
  }

  public get myNotifys() {
    return jit(this, "myNotifs", () => APP().me!.unseenNotifys.createSubHandler({
      convertResponse: (resp) => resp?.filter(({contextId}) => contextId === this.id) || [] 
    }))
  }
}



export class InviteRepo
extends SourceRepo<InviteModel, "id", InviteArgs> {
  constructor() {
    super({
      idPropName: "id",
      repoId: "invite",
      ...commonPathSetups("invite"),
      allFieldsNames: Object.keys(ExampleInvite) as any
    }, {
      getJoinFilter: (key, item) => {
        switch(key) {
          // case "From": return { repo: APP().ents.user.repo, where: { id: item.fromId }}
          case "To": return { repo: APP().ents.user.repo, where: { id: item.toId }}
          case "Gather": return { repo: APP().ents.gather.repo, where: { id: item.gatherId }}
          case "Backers": return { repo: APP().ents.pairing.repo, where: { toId: item.id }}
          case "Notifications": return { repo: APP().ents.notify.repo, where: { contextId: item.id }}
        }
        throw new Error("unknown name");
      },
      // whereRules: {
      //   endedAt: null
      // }
    })
  }

  public async assertWithBacker(args: typeof InviteAPI.assertWithBacker.payloadEg) {
    const resp = (await APP().api.post(InviteAPI.assertWithBacker.path, args)) as typeof InviteAPI.assertWithBacker.responseEg;
    this.dataSource.setItem(resp.invite);
    APP().repos.pairing.dataSource.setItem(resp.pairing);
  }

  // public addContact = {
  //   accept: async (id: string) => {
  //     const resp = await APP().api.post(InviteAPI.addContact.accept.path, { id }) as typeof InviteAPI.addContact.accept.responseEg;
  //     this.dataSource.setItems(resp);
  //   },

  //   request: async (args: typeof InviteAPI.addContact.request.payloadEg) => {
  //     const resp = await APP().api.post(InviteAPI.addContact.request.path, args) as typeof InviteAPI.addContact.request.responseEg;
  //     this.dataSource.setItems(resp);
  //   }
  // }

  // public async addContact.accept(id: string) {
  //   const resp = await APP().api.post(InviteAPI.addContact.accept.path, { id }) as typeof InviteAPI.addContact.accept.responseEg;
  //   this.dataSource.setItems(resp);
  // }

  // public async addContact.request(args: typeof InviteAPI.addContact.request.payloadEg) {
  //   const resp = await APP().api.post(InviteAPI.addContact.request.path, args) as typeof InviteAPI.addContact.request.responseEg;
  //   this.dataSource.setItems(resp);
  // }
}